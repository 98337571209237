import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";
import querystring from "query-string";

export const getProfileGroups = createAsyncThunk(
  "groups/getProfileGroups",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/groups/institution/${payload?.institutionId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addProfileGroup = createAsyncThunk(
  "groups/addProfileGroup",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/groups`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addGroupCsv = createAsyncThunk(
  "groups/addGroupCsv",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/groups/profiles/csv`;
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editProfileGroup = createAsyncThunk(
  "groups/editProfileGroup",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/groups/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delProfileGroup = createAsyncThunk(
  "groups/delProfileGroup",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/groups/${payload?.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
