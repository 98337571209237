import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from '../actions/account'

export const getEnquirySchema = createAsyncThunk(
  "enquiry/getEnquirySchema",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/inquiries/schema/${payload.institutionId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const submitEnquiryForm = createAsyncThunk(
  "enquiry/submitEnquiryForm",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + "/api/inquiries";
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload.requestBody),
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getEnquiries = createAsyncThunk(
  "enquiry/getEnquiries",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + "/api/inquiries";
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getEnquiryById = createAsyncThunk(
  "enquiry/getEnquiryById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/inquiries/${payload?.enquiryId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editEnquiryById = createAsyncThunk(
  "enquiry/editEnquiryById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt)
      const url = process.env.REACT_APP_SERVER + `/api/inquiries/${payload?.enquiryId}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization
        },
        body:JSON.stringify(payload?.data)
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editEnquiryStatusById = createAsyncThunk(
  "enquiry/editEnquiryStatusById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt)
      const url = process.env.REACT_APP_SERVER + `/api/inquiries/status/${payload?.enquiryId}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization
        },
        body:JSON.stringify(payload?.data)
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delEnquiryById = createAsyncThunk(
  "enquiry/delEnquiryById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/inquiries/${payload.enquiryId}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);

      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
