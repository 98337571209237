import { Outlet } from "react-router-dom";
// @mui
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";
import LogoMain from "../components/LogoMain";
import { HEADER, NAVBAR } from "../config";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";

// ----------------------------------------------------------------------

const LayoutStyle = styled("div")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function PublicLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  return (
    <>
      <LayoutStyle>
        {/* <LogoMain /> */}

        <Outlet />
      </LayoutStyle>
    </>
  );
}
