import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";
import querystring from "query-string";

export const getGoalSchemaById = createAsyncThunk(
  "goals/getGoalSchemaById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/schema/tag/${payload?.tagId}/institutionId/${payload?.id}/me`
          : `/api/goals/schema/tag/${payload?.tagId}/institutionId/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalByIdLazy = createAsyncThunk(
  "goals/getGoalByIdLazy",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/${payload?.id}/tag/${payload?.tagId}/me`
          : `/api/goals/${payload?.id}/tag/${payload?.tagId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalById = createAsyncThunk(
  "goals/getGoalById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/${payload?.id}/me`
          : `/api/goals/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalDetailsForSubSectionEditById = createAsyncThunk(
  "goals/getGoalDetailsForSubSectionEditById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/${payload?.id}/tag/${payload?.tagId}/me`
          : `/api/goals/${payload?.id}/tag/${payload?.tagId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return { validateData, otherGoalFetch: payload?.otherGoalFetch };
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalByParentGoalId = createAsyncThunk(
  "goals/getGoalByParentGoalId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/parent/${payload?.parentId}/me`
          : `/api/goals/parent/${payload?.parentId}`)
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addGoals = createAsyncThunk(
  "goals/addGoals",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const createDuplicateGoals = createAsyncThunk(
  "goals/createDuplicateGoals",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/duplicate`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalsByTagId = createAsyncThunk(
  "goals/getGoalsByTagId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/goals/tag/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalsByProductId = createAsyncThunk(
  "goals/getGoalsByProductId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/product/${payload?.id}/me`
          : `/api/goals/product/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalsByModuleId = createAsyncThunk(
  "goals/getGoalsByModuleId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/type/${payload?.module}/module/${payload?.id}/me`
          : `/api/goals/type/${payload?.module}/module/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalsByMultipleTagIds = createAsyncThunk(
  "goals/getGoalsByMultipleTagIds",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/tag_ids/me?${querystring.stringify({
            tag_ids: payload?.tag_ids,
          })}`
          : `/api/goals/tag_ids?${querystring.stringify({
            tag_ids: payload?.tag_ids,
          })}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editGoalById = createAsyncThunk(
  "goals/editGoalById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      let error = {}
      if (err.message === "The goal was modified and has new data") {
        error.statusCode = 409
      }
      return thunkApi.rejectWithValue(error.statusCode ? error : err);
    }
  }
);

export const editChildGoalById = createAsyncThunk(
  "goals/editChildGoalById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      let error = {}
      if (err.message === "The goal was modified and has new data") {
        error.statusCode = 409
      }
      return thunkApi.rejectWithValue(error.statusCode ? error : err);
    }
  }
);

export const markGoalAsFavorite = createAsyncThunk(
  "goals/markGoalAsFavorite",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/favorites/update/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return { goalId: payload?.id, validateData, isFavorite: validateData?.data?.goal?.isFavorite };
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      let error = {}
      if (err.message === "The goal was modified and has new data") {
        error.statusCode = 409
      }
      return thunkApi.rejectWithValue(error.statusCode ? { error, goalId: payload?.id, isFavorite: payload?.prevIsFav } : { err, goalId: payload?.id, isFavorite: payload?.prevIsFav });
    }
  }
);

export const delGoalById = createAsyncThunk(
  "goals/delGoalById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/${payload.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);

      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getGoalsForCalender = createAsyncThunk(
  "goals/getGoalsForCalender",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser ? `/api/goals/calender/institution/${payload?.id}/me` : `/api/goals/calender/institution/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editMultipleGoals = createAsyncThunk(
  "goals/editMultipleGoals",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/goals/bulk/start_end_date`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getPublicGoalSchema = createAsyncThunk(
  "goals/getPublicGoalSchema",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/goals/schema/tag/${payload?.tagName}/institutionId/${payload?.id}/public`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addPublicGoals = createAsyncThunk(
  "goals/addPublicGoals",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/public`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editBulkGoals = createAsyncThunk(
  "goals/editBulkGoals",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/bulk`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getClientLocationProfileForGoal = createAsyncThunk(
  "goals/getClientLocationProfileForGoal",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/goals/schema/tag/${payload?.tagId}/institutionId/${payload?.id}/me`
          : `/api/goals/client/location/profiles/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const checkGoalConflicts = createAsyncThunk(
  "goals/checkGoalConflicts",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/conflict`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const checkRecentlyUpdatedGoal = createAsyncThunk(
  "goals/checkRecentlyUpdatedGoal",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/goals/${payload?.id}/timestamp/${payload?.timestamp}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      let error = {}
      if (err.message === "The goal was modified and has new data") {
        error.statusCode = 409
      }
      return thunkApi.rejectWithValue(error.statusCode ? error : err);
    }
  }
);