import { createSlice } from "@reduxjs/toolkit";
import {
  getAllHolidays,
  getAllHolidayList,
  addHolidayList,
  delHolidayList,
  addHolidayDate,
  delHolidayDate,
  getHolidayDateListByKey,
} from "../actions/holidays";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAllHolidays: false,
  isLoadingAddHolidayList: false,
  isLoadingDelHolidayList: false,
  allHolidays: [],
  isLoadingAllHolidaysByKey: false,
  listOfHolidays: [],
  holidayDateList: [],
  isLoadingAddHolidayDate: false,
  isLoadingDelHolidayDate: false,
};

const holidaysSlice = createSlice({
  name: "holidays",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [getAllHolidays.pending]: (state, action) => {
      state.isLoadingAllHolidays = true;
      state.error = false;
    },
    [getAllHolidays.fulfilled]: (state, action) => {
      state.isLoadingAllHolidays = false;
      state.allHolidays = action?.payload;
      state.error = false;
    },
    [getAllHolidays.rejected]: (state, action) => {
      state.isLoadingAllHolidays = false;
      state.error = true;
    }, //getAllHolidayList
    [getAllHolidayList.pending]: (state, action) => {
      state.isLoadingAllHolidaysByKey = true;
      state.error = false;
    },
    [getAllHolidayList.fulfilled]: (state, action) => {
      state.isLoadingAllHolidaysByKey = false;
      state.listOfHolidays = action?.payload?.data;
      state.error = false;
    },
    [getAllHolidayList.rejected]: (state, action) => {
      state.isLoadingAllHolidaysByKey = false;
      state.error = true;
    },

    //addHolidayList
    [addHolidayList.pending]: (state, action) => {
      state.isLoadingAddHolidayList = true;
      state.error = false;
    },
    [addHolidayList.fulfilled]: (state, action) => {
      state.isLoadingAddHolidayList = false;
      state.error = false;
    },
    [addHolidayList.rejected]: (state, action) => {
      state.isLoadingAddHolidayList = false;
      state.error = true;
    },
    //deleteHolidayList
    [delHolidayList.pending]: (state, action) => {
      state.isLoadingDelHolidayList = true;
      state.error = false;
    },
    [delHolidayList.fulfilled]: (state, action) => {
      state.isLoadingDelHolidayList = false;
      state.error = false;
    },
    [delHolidayList.rejected]: (state, action) => {
      state.isLoadingDelHolidayList = false;
      state.error = true;
    },
    [addHolidayDate.pending]: (state, action) => {
      state.isLoadingAddHolidayDate = true;
      state.error = false;
    },
    [addHolidayDate.fulfilled]: (state, action) => {
      state.isLoadingAddHolidayDate = false;
      state.error = false;
    },
    [addHolidayDate.rejected]: (state, action) => {
      state.isLoadingAddHolidayDate = false;
      state.error = true;
    }, //delHolidayDate
    [delHolidayDate.pending]: (state, action) => {
      state.isLoadingDelHolidayDate = true;
      state.error = false;
    },
    [delHolidayDate.fulfilled]: (state, action) => {
      state.isLoadingDelHolidayDate = false;
      state.error = false;
    },
    [delHolidayDate.rejected]: (state, action) => {
      state.isLoadingDelHolidayDate = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
    //getHolidayDateListByKey
    [getHolidayDateListByKey.pending]: (state, action) => {
      state.isLoadingHolidayDateList = true;
      state.error = false;
    },
    [getHolidayDateListByKey.fulfilled]: (state, action) => {
      state.isLoadingHolidayDateList = false;
      state.holidayDateList = action?.payload?.data;
      state.error = false;
    },
    [getHolidayDateListByKey.rejected]: (state, action) => {
      state.isLoadingHolidayDateList = false;
      state.error = true;
    },
  },
});

export const getError = (state) => state.holidays.error;
export const getIsLoadingAllHolidays = (state) =>
  state.holidays.isLoadingAllHolidays;
export const getAllHolidaysArray = (state) => state.holidays.allHolidays;
export const getIsLoadingAllHolidaysByKey = (state) =>
  state.holidays.isLoadingAllHolidaysByKey;
export const getListOfHolidays = (state) => state.holidays.listOfHolidays;
export const getIsLoadingAddHolidayList = (state) =>
  state.holidays.isLoadingAddHolidayList;
export const getIsLoadingDelHolidayList = (state) =>
  state.holidays.isLoadingDelHolidayList;
export const getIsLoadingAddHolidayDate = (state) =>
  state.holidays.isLoadingAddHolidayDate;
export const getIsLoadingDelHolidayDate = (state) =>
  state.holidays.isLoadingDelHolidayDate;
export const getHolidayDateList = (state) => state.holidays.holidayDateList;
export const getIsLoadingHolidayDateList = (state) =>
  state.holidays.isLoadingHolidayDateList;

export default holidaysSlice;
