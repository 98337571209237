import { createSlice } from "@reduxjs/toolkit";
import { getVersionTracking } from "../actions/versionTracking";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingVersionTracking: false,
  versionFirstLoad: false,
  versionTracking: {},
};

const versionTrackingSlice = createSlice({
  name: "versionTracking",
  initialState,
  reducers: {
    setVersionFirstLoad(state, action) {
      state.versionFirstLoad = action.payload;
    },
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [getVersionTracking.pending]: (state, action) => {
      state.isLoadingVersionTracking = true;
      state.error = false;
    },
    [getVersionTracking.fulfilled]: (state, action) => {
      state.isLoadingVersionTracking = false;
      state.versionTracking = action?.payload;
      state.error = false;
    },
    [getVersionTracking.rejected]: (state, action) => {
      state.isLoadingVersionTracking = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.versionTracking.error;
export const getIsLoadingVersionTracking = (state) => state.versionTracking.isLoadingVersionTracking;
export const getVersion = (state) => state.versionTracking.versionTracking;
export const getVersionFirstLoad = (state) => state.versionTracking.versionFirstLoad;

export const {
  setVersionFirstLoad
} = versionTrackingSlice.actions;

export default versionTrackingSlice;
