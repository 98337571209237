import { createSlice } from "@reduxjs/toolkit";
import { createBookingSlots, getBookingSchedule, delBookingSchedule, generateBookingSlots, getBookingSlotsByDateandModuleId, bookSlots, getBookingsByModuleId, getMyBookingsByModuleId, getMyBookings, getBookingTemplateByModuleId, cancelSlots, getSlotDatesByTemplateIdAndDate } from "../actions/bookings";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingCreateBookingSlot: false,
    isLoadingGetBookingSchedule: false,
    bookingScheduleDetails: {},
    isLoadingDelBookingSchedule: false,
    isLoadingGeneratebookingSlots: false,
    bookingSlots: [],
    isLoadingGetSlotsByDateAndModuleId: false,
    slotsForDate: [],
    isLoadingBookSlotsForUser: false,
    isLoadingBookSlots: false,
    isLoadingGetBookingsByModuleId: false,
    bookingsList: [],
    isLoadingGetBookingTempByModId: false,
    bookingTemplatesList: [],
    returnDataForBookingTemplate: [],
    isLoadingCancelSlots: false,
    reSchedule:false,
    reScheduleData:{},
    isLoadingGetSlotDatesByTemplateIdAndDate:false,
    slotDates:[],
    selectedSchedule:[],
    selectedScheduleForGoalsOverview:[],
    startDate:"",
    startDateForGoalsOverview:"",
    endDate:"",
    endDateForGoalsOverview:""
};

const bookingSlice = createSlice({
    name: "bookings",
    initialState,
    reducers: {
        toggleDropdownStatus(state) {
            state.changeDropdownStatus = !state.changeDropdownStatus;
        },
        setReschedule(state,action) {
            state.reSchedule = action.payload;
        },
        setReScheduleData(state,action){
            state.reScheduleData=action.payload
        },
        setSelectedSchedule(state,action){
            state.selectedSchedule=action.payload
        },
        setSelectedScheduleForGoalsOverview(state,action){
            state.selectedScheduleForGoalsOverview=action.payload
        },
        setStartDate(state,action){
            state.startDate=action.payload
        },
        setStartDateForGoalsOverview(state,action){
            state.startDateForGoalsOverview=action.payload
        },
        setEndDate(state,action){
            state.endDate=action.payload
        },
        setEndDateForGoalsOverView(state,action){
            state.endDateForGoalsOverview=action.payload
        }
    },
    extraReducers: {
        [createBookingSlots.pending]: (state, action) => {
            state.isLoadingCreateBookingSlot = true;
            state.error = false;
        },
        [createBookingSlots.fulfilled]: (state, action) => {
            state.isLoadingCreateBookingSlot = false;
            state.returnDataForBookingTemplate = action?.payload;
            state.error = false;
        },
        [createBookingSlots.rejected]: (state, action) => {
            state.isLoadingCreateBookingSlot = false;
            state.error = true;
        },//getBookingSchedule
        [getBookingSchedule.pending]: (state, action) => {
            state.isLoadingGetBookingSchedule = true;
            state.error = false;
        },
        [getBookingSchedule.fulfilled]: (state, action) => {
            state.isLoadingGetBookingSchedule = false;
            state.bookingScheduleDetails = action?.payload?.template;
            state.error = false;
        },
        [getBookingSchedule.rejected]: (state, action) => {
            state.isLoadingGetBookingSchedule = false;
            state.error = true;
        },//delBookingSchedule
        [delBookingSchedule.pending]: (state, action) => {
            state.isLoadingDelBookingSchedule = true;
            state.error = false;
        },
        [delBookingSchedule.fulfilled]: (state, action) => {
            state.isLoadingDelBookingSchedule = false;
            state.error = false;
        },
        [delBookingSchedule.rejected]: (state, action) => {
            state.isLoadingDelBookingSchedule = false;
            state.error = true;
        },//generateBookingSlots
        [generateBookingSlots.pending]: (state, action) => {
            state.isLoadingGeneratebookingSlots = true;
            state.error = false;
        },
        [generateBookingSlots.fulfilled]: (state, action) => {
            state.isLoadingGeneratebookingSlots = false;
            state.bookingSlots = action?.payload?.data;
            state.error = false;
        },
        [generateBookingSlots.rejected]: (state, action) => {
            state.isLoadingGeneratebookingSlots = false;
            state.error = true;
        },//getBookingSlotsByDateandModuleId
        [getBookingSlotsByDateandModuleId.pending]: (state, action) => {
            state.isLoadingGetSlotsByDateAndModuleId = true;
            state.error = false;
        },
        [getBookingSlotsByDateandModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetSlotsByDateAndModuleId = false;
            state.slotsForDate = action?.payload;
            state.error = false;
        },
        [getBookingSlotsByDateandModuleId.rejected]: (state, action) => {
            state.isLoadingGetSlotsByDateAndModuleId = false;
            state.error = true;
        },//bookSlots
        [bookSlots.pending]: (state, action) => {
            state.isLoadingBookSlots = true;
            state.error = false;
        },
        [bookSlots.fulfilled]: (state, action) => {
            state.isLoadingBookSlots = false;
            state.error = false;
        },
        [bookSlots.rejected]: (state, action) => {
            state.isLoadingBookSlots = false;
            state.error = true;
        },//getBookingsByModuleId
        [getBookingsByModuleId.pending]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = true;
            state.error = false;
        },
        [getBookingsByModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.bookingsList = action?.payload;
            state.error = false;
        },
        [getBookingsByModuleId.rejected]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.error = true;
        },//getMyBookingsByModuleId
        [getMyBookingsByModuleId.pending]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = true;
            state.error = false;
        },
        [getMyBookingsByModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.bookingsList = action?.payload;
            state.error = false;
        },
        [getMyBookingsByModuleId.rejected]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.error = true;
        },//getMyBookings
        [getMyBookings.pending]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = true;
            state.error = false;
        },
        [getMyBookings.fulfilled]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.bookingsList = action?.payload;
            state.error = false;
        },
        [getMyBookings.rejected]: (state, action) => {
            state.isLoadingGetBookingsByModuleId = false;
            state.error = true;
        },//getAllBookingTemplate
        [getBookingTemplateByModuleId.pending]: (state, action) => {
            state.isLoadingGetBookingTempByModId = true;
            state.error = false;
        },
        [getBookingTemplateByModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetBookingTempByModId = false;
            state.bookingTemplatesList = action?.payload;
            state.error = false;
        },
        [getBookingTemplateByModuleId.rejected]: (state, action) => {
            state.isLoadingGetBookingTempByModId = false;
            state.error = true;
        },//cancelSlots
        [cancelSlots.pending]: (state, action) => {
            state.isLoadingCancelSlots = true;
            state.error = false;
        },
        [cancelSlots.fulfilled]: (state, action) => {
            state.isLoadingCancelSlots = false;
            state.error = false;
        },
        [cancelSlots.rejected]: (state, action) => {
            state.isLoadingCancelSlots = false;
            state.error = true;
        },
        [getSlotDatesByTemplateIdAndDate.pending]: (state, action) => {
            state.isLoadingGetSlotDatesByTemplateIdAndDate = true;
            state.error = false;
        },
        [getSlotDatesByTemplateIdAndDate.fulfilled]: (state, action) => {
            state.isLoadingGetSlotDatesByTemplateIdAndDate = false;
            state.slotDates = action?.payload;
            state.error = false;
        },
        [getSlotDatesByTemplateIdAndDate.rejected]: (state, action) => {
            state.isLoadingGetSlotDatesByTemplateIdAndDate = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.bookings.error;
export const getIsLoadingCreateBookingSlot = (state) => state.bookings.isLoadingCreateBookingSlot;
export const getIsLoadingGetBookingSchedule = (state) => state.bookings.isLoadingGetBookingSchedule;
export const getBookingScheduleDetails = (state) => state.bookings.bookingScheduleDetails;
export const getIsLoadingDelBookingSchedule = (state) => state.bookings.isLoadingDelBookingSchedule;
export const getIsLoadingSlotsByDateAndModuleId = (state) => state.bookings.isLoadingGetSlotsByDateAndModuleId;
export const getSlotsListForDate = (state) => state.bookings.slotsForDate;
export const getIsLoadingGeneratebookingSlots = (state) => state.bookings.isLoadingGeneratebookingSlots;
export const getIsLoadingBookSlots = (state) => state.bookings.isLoadingBookSlots;
export const getIsLoadingBookingsByModuleId = (state) => state.bookings.isLoadingGetBookingsByModuleId;
export const getBookingsList = (state) => state.bookings.bookingsList;
export const getIsLoadingBookingTempByModId = (state) => state.bookings.isLoadingGetBookingTempByModId;
export const getBookingTemplatesList = (state) => state.bookings.bookingTemplatesList;
export const getReturnDataForBookingTemplate = (state) => state.bookings.returnDataForBookingTemplate;
export const getIsLoadingCancelSlots = (state) => state.bookings.isLoadingCancelSlots;
export const getIsLoadingGetSlotDatesByTemplateIdAndDate = (state) => state.bookings.isLoadingGetSlotDatesByTemplateIdAndDate;
export const getSlotDates = (state) => state.bookings.slotDates;

export const getRescheduleStatus=(state)=>state.bookings.reSchedule
export const getRescheduleData=(state)=>state.bookings.reScheduleData
export const getReloadBookingsApi=(state)=>state.bookings.reloadBookingsApi

export const getSelectedSchedule=(state)=>state.bookings.selectedSchedule
export const getSelectedScheduleForGoalsOverview=(state)=>state.bookings.selectedScheduleForGoalsOverview

export const getStartDate=(state)=>state.bookings.startDate
export const getEndDate=(state)=>state.bookings.endDate

export const getStartDateForGoalsOverview=(state)=>state.bookings.startDateForGoalsOverview
export const getEndDateForGoalsOverview=(state)=>state.bookings.endDateForGoalsOverview

export const {setReschedule,setReScheduleData ,setSelectedSchedule,setSelectedScheduleForGoalsOverview,setEndDate,setStartDate,setEndDateForGoalsOverView,setStartDateForGoalsOverview }=bookingSlice.actions

export default bookingSlice;
