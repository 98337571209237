import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import { logoutUser } from "./account";

// export const getAllVendorsItemsById = createAsyncThunk(
//     "vendorItems/getAllVendorsItemsById",
//     async (payload, thunkApi) => {
//         try {
//             const url = process.env.REACT_APP_SERVER + `/api/vendorItems/vendor/${payload?.id}`;
//             const Authorization = composeAuth(payload?.jwt);
//             const res = await fetch(url, {
//                 method: "GET",
//                 headers: {
//                     "Content-type": "application/json",
//                     Authorization,
//                 },
//             });

//             const response = await res.json();
//             const validateData = checkStatus(response);
//             return validateData;
//         } catch (err) {
//             if (err instanceof AuthError) {
//                 thunkApi.dispatch(logoutUser());
//             }
//             return thunkApi.rejectWithValue(err);
//         }
//     }
// );

export const addWorkorderItem = createAsyncThunk(
    "workorderItems/addWorkorderItem",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/workOrderItems`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editWorkorderItemById = createAsyncThunk(
    "workorderItems/editVendorItemsById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/workOrderItems/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delWorkorderItem = createAsyncThunk(
    "workorderItems/delWorkorderItem",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/workOrderItems/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
