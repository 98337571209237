import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import { logoutUser } from "./account";

export const getAllVendorsItemsForInstitution = createAsyncThunk(
    "vendorItems/getAllVendorsItemsForInstitution",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorItems`;
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getAllVendorsItemsById = createAsyncThunk(
    "vendorItems/getAllVendorsItemsById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorItems/vendor/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addVendorItem = createAsyncThunk(
    "vendorItems/addVendorItem",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorItems`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editVendorItemsById = createAsyncThunk(
    "vendorItems/editVendorItemsById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorItems/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delVendorItem = createAsyncThunk(
    "vendorItems/delVendorItem",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorItems/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
