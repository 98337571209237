import { createSlice } from "@reduxjs/toolkit";
import {
  getProductSchema,
  submitProductForm,
  getProductById,
  editProductById,
  delProductById,
  getAllProducts,
  getProductTagByInstitutionId
} from "../actions/products";
import { addEmail } from "../actions/email"
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  referralStatus: "",
  isLoadingProductSubmission: false,
  isLoadingGetAllProducts: false,
  isLoadingEditProduct: false,
  isLoadingEditProductStatus: false,
  isLoadingDelProduct: false,
  enquiries: [],
  productList: [],
  product: {},
  isLoadingGetProductById: false,
  isLoadingProductSchema: false,
  isLoadingGetProductTagByInstitutionId: false,
  productSchema: [],
  productGoals: [],
  productGoalsSchema: [],
  productsTags: {},
  tableFilters: {},
  columnFilters: [],
  productClients: [],
  productClientsSchema: [],
  productVendors: [],
  productVendorsSchema: [],
  productEmails: [],
  productReport: []
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeProductFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeProductColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: {
    [getProductSchema.pending]: (state, action) => {
      state.isLoadingProductSchema = true;
      state.error = false;
    },
    [getProductSchema.fulfilled]: (state, action) => {
      state.isLoadingProductSchema = false;
      state.productSchema = action?.payload?.data?.products_schema;
      state.productsTags = action?.payload?.data?.products_tags;
      state.error = false;
    },
    [getProductSchema.rejected]: (state, action) => {
      state.isLoadingProductSchema = false;
      state.error = true;
    },
    [submitProductForm.pending]: (state, action) => {
      state.isLoadingProductSubmission = true;
      state.error = false;
    },
    [submitProductForm.fulfilled]: (state, action) => {
      state.isLoadingProductSubmission = false;
      state.error = false;
    },
    [submitProductForm.rejected]: (state, action) => {
      state.isLoadingProductSubmission = false;
      state.error = true;
    },
    [getAllProducts.pending]: (state, action) => {
      state.isLoadingGetAllProducts = true;
      state.error = false;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.isLoadingGetAllProducts = false;
      state.productList = action?.payload?.data?.products
        ? action?.payload?.data?.products
        : [];
      state.productSchema = action?.payload?.data?.products_schema
        ? action?.payload?.data?.products_schema
        : [];
      state.productsTags = action?.payload?.data?.products_tags
        ? action?.payload?.data?.products_tags
        : {};
      state.error = false;
    },
    [getAllProducts.rejected]: (state, action) => {
      state.isLoadingGetAllProducts = false;
      state.error = true;
    },
    [getProductById.pending]: (state, action) => {
      state.isLoadingGetProductById = true;
      state.error = false;
    },
    [getProductById.fulfilled]: (state, action) => {
      state.isLoadingGetProductById = false;
      state.product = action?.payload?.data?.product
        ? action?.payload?.data?.product
        : {};
      state.productSchema = action?.payload?.data?.products_schema
        ? action?.payload?.data?.products_schema
        : [];
      state.productGoals = action?.payload?.data?.goals
        ? action?.payload?.data?.goals
        : {};
      state.productGoalsSchema = action?.payload?.data?.goalsSchema
        ? action?.payload?.data?.goalsSchema
        : [];
      state.productsTags = action?.payload?.data?.products_schema
        ? action?.payload?.data?.products_tags
        : [];
      state.productClients = action?.payload?.data?.clients;
      state.productClientsSchema = action?.payload?.data?.clients_schema;
      // state.productVendors = action?.payload?.data;
      // state.productVendorsSchema = action?.payload?.data;
      state.productEmails = action?.payload?.data?.emails;
      state.productReport = action?.payload?.data?.reports;
      state.error = false;
    },
    [addEmail.fulfilled]: (state, action) => {
      if (action?.payload?.module === "product") {
        state.productEmails = [...(state?.productEmails?.length ? state?.productEmails : []), ...(action?.payload?.returnedData?.data?.length ? action?.payload?.returnedData?.data : [])];
      }
      state.error = false;
    },
    [getProductById.rejected]: (state, action) => {
      state.isLoadingGetProductById = false;
      state.error = true;
    },
    [editProductById.pending]: (state, action) => {
      state.isLoadingEditProduct = true;
      state.error = false;
    },
    [editProductById.fulfilled]: (state, action) => {
      state.isLoadingEditProduct = false;
      state.product = action?.payload?.data?.product
        ? action?.payload?.data?.product
        : {};
      state.productSchema = action?.payload?.data?.products_schema
        ? action?.payload?.data?.products_schema
        : [];
      state.error = false;
    },
    [editProductById.rejected]: (state, action) => {
      state.isLoadingEditProduct = false;
      state.error = true;
    },
    [delProductById.pending]: (state, action) => {
      state.isLoadingDelProduct = true;
      state.error = false;
    },
    [delProductById.fulfilled]: (state, action) => {
      state.isLoadingDelProduct = false;
      state.error = false;
    },
    [delProductById.rejected]: (state, action) => {
      state.isLoadingDelProduct = false;
      state.error = true;
    },
    [getProductTagByInstitutionId.pending]: (state, action) => {
      state.isLoadingGetProductTagByInstitutionId = true;
      state.error = false;
    },
    [getProductTagByInstitutionId.fulfilled]: (state, action) => {
      state.isLoadingGetProductTagByInstitutionId = false;
      state.productsTags = action?.payload?.data
      state.error = false;
    },
    [getProductTagByInstitutionId.rejected]: (state, action) => {
      state.isLoadingGetProductTagByInstitutionId = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.product.error;
export const getReferralStatus = (state) => state.product.referralStatus;
export const getIsLoadingProductSubmission = (state) =>
  state.product.isLoadingProductSubmission;
export const getEnquiriesArray = (state) => state.product.enquiries;
export const getIsLoadingEditProduct = (state) =>
  state.product.isLoadingEditProduct;
export const getIsLoadingEditProductStatus = (state) =>
  state.product.isLoadingEditProductStatus;

export const getIsLoadingGetProductById = (state) =>
  state.product.isLoadingGetProductById;
export const getProductSchemaArray = (state) => state.product.productSchema;

export const getIsLoadingProductSchema = (state) =>
  state.product.isLoadingProductSchema;

export const getIsLoadingDelProduct = (state) =>
  state.product.isLoadingDelProduct;
export const getIsLoadingGetAllProduct = (state) =>
  state.product.isLoadingGetAllProducts;
export const getProductList = (state) => state.product.productList;
export const getProductDetails = (state) => state.product.product;
export const getProductsTags = (state) => state.product.productsTags;
export const getTableFilters = (state) => state.product.tableFilters;
export const getColumnFilters = (state) => state.product.columnFilters;
export const getProductGoals = (state) => state.product.productGoals;
export const getProductGoalsSchema = (state) => state.product.productGoalsSchema;

export const getProductClients = (state) => state.product.productClients;
export const getProductClientsSchema = (state) => state.product.productClientsSchema;
export const getProductVendors = (state) => state.product.productVendors;
export const getProductVendorsSchema = (state) => state.product.productVendorsSchema;
export const getProductEmails = (state) => state.product.productEmails;
export const getProductReport = (state) => state.product.productReport;
export const getIsLoadingGetProductTagByInstitutionId = (state) => state.product.isLoadingGetProductTagByInstitutionId;

export const { storeProductFilters, storeProductColumnFilters } = productSlice.actions;

export default productSlice;
