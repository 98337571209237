import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//assets
import LogoImage from '../assets/images/logo.png'
//reducers
import { getCurrentTheme } from '../redux/slices/institution'
import { getUserNavigationArray } from 'src/redux/slices/navigation';

// ----------------------------------------------------------------------

LogoMain.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoMain({ disabledLink = false, sx }) {
  const currentTheme = useSelector(getCurrentTheme)
  const userNavigations = useSelector(getUserNavigationArray);

  const logo = currentTheme?.logo ? currentTheme?.logo : LogoImage

  if (disabledLink) {
    return <><img style={{ width: 200 }} src={logo} /></>;
  }

  return <RouterLink to={
    "/" + userNavigations?.slice()?.sort((a, b) => {
      const sectionOrder = a?.section_order - b?.section_order
      if (sectionOrder === 0) {
        return a?.order - b?.order
      }
      else {
        return a?.section_order - b?.section_order
      }
    })?.[0]?.link
  }><img style={{ width: 200 }} src={logo} /></RouterLink>;
}
