import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useSelector, useDispatch } from "react-redux";
import version from '../../../package.json'
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Stack, Dialog } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
//reducers
import { getFirstName, getJwt } from '../../redux/slices/account'
import {
  getVersion, getError, getIsLoadingVersionTracking, getVersionFirstLoad, setVersionFirstLoad } from "../../redux/slices/versionTracking";
//actiosn
import { getAllNotifications } from '../../redux/actions/notifications'
import { getVersionTracking } from '../../redux/actions/versionTracking'

import {
  useInterval,
  usePrevious
} from "src/helpers/utils";
import Iconify from 'src/components/Iconify';


// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { themeLayout } = useSettings();
  
  const [ showModal, setShowModal ] = useState(false);
  const [getNotificationsStatus, setGetNotificationsStatus ] = useState(true);

  const error = useSelector(getError);
  const versionFirstLoad = useSelector(getVersionFirstLoad);
  const isLoadingVersionTracking = useSelector(getIsLoadingVersionTracking);
  const isLoadingVersionTrackingPrev = usePrevious(isLoadingVersionTracking);
  const versionCurrent = useSelector(getVersion);
  const versionCurrentPrev = usePrevious(versionCurrent);
  const firstName = useSelector(getFirstName);
  const jwt = useSelector(getJwt);

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';
  const { boot } = useIntercom();

  // User has switched back to the tab
  const onFocus = () => {
    setGetNotificationsStatus(true)
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setGetNotificationsStatus(false)
  };

  useInterval(() => {
    if (getNotificationsStatus && jwt){
      dispatch(getAllNotifications({ jwt }));
    }
  }, 3000)

  useEffect(() => {
    boot({
      name: firstName,
    })
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [])

  useEffect(() => {
    dispatch(getVersionTracking())
  }, [pathname])

  useEffect(() => {
    if (
      !error &&
      !isLoadingVersionTracking &&
      isLoadingVersionTracking !== isLoadingVersionTrackingPrev &&
      isLoadingVersionTrackingPrev !== undefined
    ) {
      if (versionCurrent?.version !== version?.version && !versionFirstLoad) {
        setShowModal(true)
      }
    }
  }, [isLoadingVersionTracking, isLoadingVersionTrackingPrev])

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showModal}
        onClose={() => { setShowModal(false); dispatch(setVersionFirstLoad(true)) }}
      ><>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 1, px: 3, textAlign: "center" }}
          >
            <Iconify
              icon="mdi:alert-circle-outline"
              style={{ color: "black", width: "100px", height: "50px" }}
            />
          </Stack>
          <Stack
            sx={{
              py: 1,
              px: 3,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: 10,
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                fontSize: "22px",
                color: "rgba(75, 75, 75, 0.76)",
              }}
            >
              There is a new update, do you want to reload?
            </div>
          </Stack>

          <Stack
            sx={{
              py: 2,
              px: 3,
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              size="medium"
              variant="outlined"
              sx={{ alignSelf: "flex-end", marginRight: "5px" }}
              onClick={() => { setShowModal(false); dispatch(setVersionFirstLoad(true)) }}
            >
              DISMISS
            </Button>
            <Button variant="contained" onClick={() => { dispatch(setVersionFirstLoad(true)); setShowModal(false); window?.location?.reload(true); }}>
              RELOAD
            </Button>
          </Stack>
        </>
      </Dialog>
    </Box>
  );
}
