import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "MMMM dd yyyy");
}

export function fDateWithDay(date) {
  return format(new Date(date), "eeee, MMMM do yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "MMM dd yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "MMM dd yyyy h:mm a");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
