import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import { logoutUser } from "./account";

export const getVendorSchema = createAsyncThunk(
    "vendor/getVendorSchema",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/vendors/schema/${payload?.institutionId}/me` : `/api/vendors/schema/${payload?.institutionId}`);
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAllVendors = createAsyncThunk(
    "vendor/getAllVendors",
    async (payload, thunkApi) => {
        try {
            const url =
                process.env.REACT_APP_SERVER +
                (payload?.isUser ? `/api/vendors/me/institution/${payload?.institutionId}` : `/api/vendors/institution/${payload?.institutionId}`);

            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            // validateData.data.byId = getById(validateData?.data?.clients, "_id");
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getVendorById = createAsyncThunk(
    "vendor/getVendorById",
    async (payload, thunkApi) => {
        try {
            const url =
                process.env.REACT_APP_SERVER +
                (payload?.isUser
                    ? `/api/vendors/${payload?.id}/me`
                    : `/api/vendors/${payload?.id}`);
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addVendor = createAsyncThunk(
    "vendor/addVendor",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendors`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.requestBody),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editVendorById = createAsyncThunk(
    "vendor/editVendorById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendors/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.requestBody),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delVendorById = createAsyncThunk(
    "vendor/delVendorById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendors/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
