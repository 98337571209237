import { createSlice } from "@reduxjs/toolkit";
import { addCampaignEmailTemp, getCampaignEmailTempByCampaignId, getCampaignEmailTemplateById, editCampaignEmailTemp, delCampaignEmailTemp } from "../actions/campaignEmailTemp";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingAddCampaignEmailTemp: false,
    isLoadingGetCampaignEmailTempByCampaignId: false,
    isLoadingGetCampaignEmailTempById: false,
    emailTempForCampaign: [],
    campaignEmailTempDetails: {},
    isLoadingEditCampEmailTemp: false,
    isLoadingDelCampaignEmailTemp: false,
};

const campaignEmailTempSlice = createSlice({
    name: "campaignEmailTemp",
    initialState,
    reducers: {
        toggleDropdownStatus(state) {
            state.changeDropdownStatus = !state.changeDropdownStatus;
        },
    },
    extraReducers: {
        [addCampaignEmailTemp.pending]: (state, action) => {
            state.isLoadingAddCampaignEmailTemp = true;
            state.error = false;
        },
        [addCampaignEmailTemp.fulfilled]: (state, action) => {
            state.isLoadingAddCampaignEmailTemp = false;
            state.error = false;
        },
        [addCampaignEmailTemp.rejected]: (state, action) => {
            state.isLoadingAddCampaignEmailTemp = false;
            state.error = true;
        },//getCampaignEmailTempByCampaignId
        [getCampaignEmailTempByCampaignId.pending]: (state, action) => {
            state.isLoadingGetCampaignEmailTempByCampaignId = true;
            state.error = false;
        },
        [getCampaignEmailTempByCampaignId.fulfilled]: (state, action) => {
            state.isLoadingGetCampaignEmailTempByCampaignId = false;
            state.emailTempForCampaign = action?.payload
            state.error = false;
        },
        [getCampaignEmailTempByCampaignId.rejected]: (state, action) => {
            state.isLoadingGetCampaignEmailTempByCampaignId = false;
            state.error = true;
        },
        [getCampaignEmailTemplateById.pending]: (state, action) => {
            state.isLoadingGetCampaignEmailTempById = true;
            state.error = false;
        },
        [getCampaignEmailTemplateById.fulfilled]: (state, action) => {
            state.isLoadingGetCampaignEmailTempById = false;
            state.campaignEmailTempDetails = action?.payload
            state.error = false;
        },
        [getCampaignEmailTemplateById.rejected]: (state, action) => {
            state.isLoadingGetCampaignEmailTempById = false;
            state.error = true;
        },//editCampaignEmailTemp
        [editCampaignEmailTemp.pending]: (state, action) => {
            state.isLoadingEditCampEmailTemp = true;
            state.error = false;
        },
        [editCampaignEmailTemp.fulfilled]: (state, action) => {
            state.isLoadingEditCampEmailTemp = false;
            state.campaignEmailTempDetails = action?.payload
            state.error = false;
        },
        [editCampaignEmailTemp.rejected]: (state, action) => {
            state.isLoadingEditCampEmailTemp = false;
            state.error = true;
        },//delCampaignEmailTemp
        [delCampaignEmailTemp.pending]: (state, action) => {
            state.isLoadingDelCampaignEmailTemp = true;
            state.error = false;
        },
        [delCampaignEmailTemp.fulfilled]: (state, action) => {
            state.isLoadingDelCampaignEmailTemp = false;
            state.error = false;
        },
        [delCampaignEmailTemp.rejected]: (state, action) => {
            state.isLoadingDelCampaignEmailTemp = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.campaignEmailTemp.error;
export const getIsLoadingAddCampaignEmailTemp = (state) => state.campaignEmailTemp.isLoadingAddCampaignEmailTemp;
export const getIsLoadingCampaignEmailTempByCampaignId = (state) => state.campaignEmailTemp.isLoadingGetCampaignEmailTempByCampaignId;
export const getEmailTempForCampaign = (state) => state.campaignEmailTemp.emailTempForCampaign;
export const getIsLoadingGetCampaignEmailTempById = (state) => state.campaignEmailTemp.isLoadingGetCampaignEmailTempById;
export const getCampaignEmailTempDetails = (state) => state.campaignEmailTemp.campaignEmailTempDetails;
export const getIsLoadingEditCampEmailTemp = (state) => state.campaignEmailTemp.isLoadingEditCampEmailTemp;
export const getIsLoadingDelCampaignEmailTemp = (state) => state.campaignEmailTemp.isLoadingDelCampaignEmailTemp;

export default campaignEmailTempSlice;
