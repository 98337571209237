import { createSlice } from "@reduxjs/toolkit";
import {
  addComments,
  getCommentsByModuleId,
  editCommentsById,
  delCommentsById,
} from "../actions/comments";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddComments: false,
  isLoadingGetAllComments: false,
  isLoadingEditComment: false,
  isLoadingDelComment: false,
  commentList: [],
  commentReturnData: {},
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  extraReducers: {
    [getCommentsByModuleId.pending]: (state, action) => {
      state.isLoadingGetAllComments = true;
      state.error = false;
    },
    [getCommentsByModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetAllComments = false;
      state.commentList = action?.payload?.data;
      state.error = false;
    },
    [getCommentsByModuleId.rejected]: (state, action) => {
      state.isLoadingGetAllComments = false;
      state.error = true;
    },
    [addComments.pending]: (state, action) => {
      state.isLoadingAddComments = true;
      state.error = false;
    },
    [addComments.fulfilled]: (state, action) => {
      state.isLoadingAddComments = false;
      state.commentReturnData = action?.payload?.data;
      state.error = false;
    },
    [addComments.rejected]: (state, action) => {
      state.isLoadingAddComments = false;
      state.error = true;
    },
    [editCommentsById.pending]: (state, action) => {
      state.isLoadingEditComment = true;
      state.error = false;
    },
    [editCommentsById.fulfilled]: (state, action) => {
      state.isLoadingEditComment = false;
      state.error = false;
    },
    [editCommentsById.rejected]: (state, action) => {
      state.isLoadingEditComment = false;
      state.error = true;
    },
    [delCommentsById.pending]: (state, action) => {
      state.isLoadingDelComment = true;
      state.error = false;
    },
    [delCommentsById.fulfilled]: (state, action) => {
      state.isLoadingDelComment = false;
      state.error = false;
    },
    [delCommentsById.rejected]: (state, action) => {
      state.isLoadingDelComment = false;
      state.error = true;
    },

    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.comments.error;
export const getIsLoadingAddComment = (state) =>
  state.comments.isLoadingAddComments;
export const getIsLoadingEditComment = (state) =>
  state.comments.isLoadingEditComment;
export const getIsLoadingAllComments = (state) =>
  state.comments.isLoadingGetAllComments;
export const getIsLoadingDelComment = (state) =>
  state.comments.isLoadingDelComment;
export const getCommentList = (state) => state.comments.commentList;
export const getCommentReturnData = (state) => state.comments.commentReturnData;

export default commentsSlice;
