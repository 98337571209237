import { createSlice } from "@reduxjs/toolkit";
import {
  addEmail,
  getEmailById,
  getAllEmailsByInstitute,
} from "../actions/email";
import {
  addReport,
  getReportById,
  getAllReportsByInstitute,
  getReportByModuleAndModuleId,
  addTagReport
} from "../actions/report";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddReport: false,
  isLoadingAllReportsForInstitute: false,
  postedData: {},
  isLoadingGetReportById: false,
  reportDetails: {},
  reportList: [],
  selectedTemplate: "",
  reportFile: "",
  reportTitle: "",
  isLoadingGetReportByModuleAndModuleId: false,
  reportsForModule: []
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
    setReportFile(state, action) {
      state.reportFile = action.payload;
    },
    setReportTitle(state, action) {
      state.reportTitle = action.payload;
    },
  },
  extraReducers: {
    [addReport.pending]: (state, action) => {
      state.isLoadingAddReport = true;
      state.error = false;
    },
    [addReport.fulfilled]: (state, action) => {
      state.isLoadingAddReport = false;
      state.postedData = action?.payload?.data;
      state.reportDetails = action?.payload?.data;
      state.error = false;
    },
    [addReport.rejected]: (state, action) => {
      state.isLoadingAddReport = false;
      state.error = true;
    },
    [addTagReport.pending]: (state, action) => {
      state.isLoadingAddReport = true;
      state.error = false;
    },
    [addTagReport.fulfilled]: (state, action) => {
      state.isLoadingAddReport = false;
      state.postedData = action?.payload?.data;
      state.reportDetails = action?.payload?.data;
      state.error = false;
    },
    [addTagReport.rejected]: (state, action) => {
      state.isLoadingAddReport = false;
      state.error = true;
    },
    [getReportById.pending]: (state, action) => {
      state.isLoadingGetReportById = true;
      state.error = false;
    },
    [getReportById.fulfilled]: (state, action) => {
      state.isLoadingGetReportById = false;
      state.reportDetails = action?.payload?.data?.report;
      state.error = false;
    },
    [getReportById.rejected]: (state, action) => {
      state.isLoadingGetReportById = false;
      state.error = true;
    },//getReportByModuleAndModuleId
    [getReportByModuleAndModuleId.pending]: (state, action) => {
      state.isLoadingGetReportByModuleAndModuleId = true;
      state.error = false;
    },
    [getReportByModuleAndModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetReportByModuleAndModuleId = false;
      state.reportsForModule = action?.payload?.data;
      state.error = false;
    },
    [getReportByModuleAndModuleId.rejected]: (state, action) => {
      state.isLoadingGetReportByModuleAndModuleId = false;
      state.error = true;
    },
    [getAllReportsByInstitute.pending]: (state, action) => {
      state.isLoadingAllReportsForInstitute = true;
      state.error = false;
    },
    [getAllReportsByInstitute.fulfilled]: (state, action) => {
      state.isLoadingAllReportsForInstitute = false;
      state.reportList = action?.payload?.data?.reports;
      state.error = false;
    },
    [getAllReportsByInstitute.rejected]: (state, action) => {
      state.isLoadingAllReportsForInstitute = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.reports.error;
export const getIsLoadingAddReport = (state) =>
  state.reports.isLoadingAddReport;
export const getPostedData = (state) => state.reports.postedData;
export const getIsLoadingGetReportById = (state) =>
  state.reports.isLoadingGetReportById;
export const getIsLoadingAllReportsForInstitute = (state) =>
  state.reports.isLoadingAllReportsForInstitute;
export const getReportDetails = (state) => state.reports.reportDetails;
export const getReportsList = (state) => state.reports.reportList;
export const getSelectedTemplate = (state) => state.reports.selectedTemplate;
export const getReportFile = (state) => state.reports.reportFile;
export const getReportTitle = (state) => state.reports.reportTitle;

export const getIsLoadingReportByModuleAndModuleId = (state) => state.reports.isLoadingGetReportByModuleAndModuleId;
export const getReportsForModule = (state) => state.reports.reportsForModule;

export const {
  setSelectedTemplate,
  setReportFile,
  setReportTitle
} = reportSlice.actions;

export default reportSlice;
