// routes
import Router from "./routes"
import { IntercomProvider } from "react-use-intercom";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/settings";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import ErrorBoundary from "./errorBoundary"

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';


// MSAL configuration
const msalInstance = new PublicClientApplication(msalConfig);

const INTERCOM_APP_ID = "avku1axn";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ErrorBoundary>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <ScrollToTop />
                  <Router />
                </IntercomProvider>
              </ErrorBoundary>
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </MsalProvider>
  );
}
