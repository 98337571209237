import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";
import querystring from "query-string";

export const getInstitutionByDomainName = createAsyncThunk(
  "institution/institutionByDomainName",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        `/api/institutions/domain/${payload?.domainName}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getInstitutionByIds = createAsyncThunk(
  "institution/getInstitutionByIds",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/institutions/institution/ids?${querystring.stringify({
          ids: payload?.ids,
        })
        }`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
