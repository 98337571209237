import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import querystring from "query-string";
import { logoutUser } from "../actions/account";

export const getCampaignEmailTempByCampaignId = createAsyncThunk(
    "campaignEmail/getCampaignsForInstitution",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/campaignEmailTemplates/campaign/${payload?.campaignId}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addCampaignEmailTemp = createAsyncThunk(
    "campaignEmail/addCampaignEmailTemp",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaignEmailTemplates`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editCampaignEmailTemp = createAsyncThunk(
    "campaignEmail/editCampaignEmailTemp",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaignEmailTemplates/${payload?.id}/institution/${payload?.institutionId}`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delCampaignEmailTemp = createAsyncThunk(
    "campaignEmail/delCampaignEmailTemp",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/campaignEmailTemplates/${payload?.id}`;
            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getCampaignEmailTemplateById = createAsyncThunk(
    "campaign/getCampaignEmailTemplateById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaignEmailTemplates/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


