import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from './account'

export const getProductSchema = createAsyncThunk(
  "product/getProductSchema",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/products/schema/${payload.institutionId}/me` : `/api/products/schema/${payload.institutionId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const submitProductForm = createAsyncThunk(
  "product/submitProductForm",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + "/api/products";
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload.requestBody),
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async (payload, thunkApi) => {
    try {

      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/products/institution/${payload?.institutionId}/me` : `/api/products/institution/${payload?.institutionId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + (payload.isUser ? `/api/products/${payload?.productId}/me` : `/api/products/${payload?.productId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editProductById = createAsyncThunk(
  "product/editProductById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt)
      const url = process.env.REACT_APP_SERVER + `/api/products/${payload?.productId}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization
        },
        body: JSON.stringify(payload?.requestBody)
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);





export const delProductById = createAsyncThunk(
  "product/delProductById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/products/${payload.productId}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);

      return validateData?.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProductTagByInstitutionId = createAsyncThunk(
  "product/getProductTagByInstitutionId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/products/tags/institution/${payload?.institutionId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
