

import { createSlice } from "@reduxjs/toolkit";

import {
    getVendorSchema,
    getAllVendors,
    getVendorById,
    addVendor,
    editVendorById,
    delVendorById
} from "../actions/vendor"
import { addEmail } from "../actions/email";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingGetVendorSchema: false,
    isLoadingAddVendor: false,
    isLoadingGetAllVendors: false,
    isLoadingGetVendorById: false,
    isLoadingEditVendorById: false,
    isLoadingDelVendor: false,
    vendorSchema: [],
    vendorList: [],
    vendorDetails: [],
    tableFilters: {},
    columnFilters: [],
    vendorGoals: [],
    vendorGoalsSchema: [],
    vendorProducts: [],
    vendorProductsSchema: [],
    vendorProductTag: {},
    vendorTags: {},
    workorderTag: {},
    vendorEmails: [],
    vendorReports: [],
    vendorContractTemplates: [],
    vendorContracts: []
};

const vendorSlice = createSlice({
    name: "vendor",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
        storeVendorFilters(state, action) {
            state.tableFilters = action.payload;
        },
        storeVendorColumnFilters(state, action) {
            state.columnFilters = action.payload;
        },
        storeVendorColumnFilters(state, action) {
            state.columnFilters = action.payload;
        },
    },
    extraReducers: {
        [getVendorSchema.pending]: (state, action) => {

            state.isLoadingGetVendorSchema = true;
            state.error = false;
        },
        [getVendorSchema.fulfilled]: (state, action) => {
            state.isLoadingGetVendorSchema = false;
            state.vendorSchema = action?.payload?.data;
            state.error = false;
        },
        [getVendorSchema.rejected]: (state, action) => {
            state.isLoadingGetVendorSchema = false;
            state.error = true;
        },
        [getVendorById.pending]: (state, action) => {
            state.isLoadingGetVendorById = true;
            state.error = false;
        },
        [getVendorById.fulfilled]: (state, action) => {
            state.isLoadingGetVendorById = false;
            state.vendorDetails = action?.payload?.data?.vendor;
            state.vendorSchema = action?.payload?.data?.vendor_schema;;
            state.error = false;
            state.vendorGoals = action?.payload?.data?.goals;
            state.vendorGoalsSchema = action?.payload?.data?.goalsSchmea;
            state.vendorProducts = action?.payload?.data?.product_data;
            state.vendorProductsSchema = action?.payload?.data?.product_schemas;
            state.vendorProductTag = action?.payload?.data?.product_tag?.[0];
            state.vendorTags = action?.payload?.data?.vendor_tags;
            state.workorderTag = action?.payload?.data?.vendorWork_tag
            state.vendorEmails = action?.payload?.data?.emails;
            state.vendorReports = action?.payload?.data?.reports;
            state.vendorContractTemplates = action?.payload?.data?.contract_templates;
            state.vendorContracts = action?.payload?.data?.contracts;
        },
        [getVendorById.rejected]: (state, action) => {
            state.isLoadingGetVendorById = false;
            state.error = true;
        },
        [addEmail.fulfilled]: (state, action) => {
            if (action?.payload?.module === "vendor") {
                state.vendorEmails = [...(state?.vendorEmails?.length ? state?.vendorEmails : []), ...(action?.payload?.returnedData?.data?.length ? action?.payload?.returnedData?.data : [])];
            }
            state.error = false;
        },
        [addVendor.pending]: (state, action) => {
            state.isLoadingAddVendor = true;
            state.error = false;
        },
        [addVendor.fulfilled]: (state, action) => {
            state.isLoadingAddVendor = false;
            state.vendorDetails = action?.payload?.data;
            state.error = false;
        },
        [addVendor.rejected]: (state, action) => {
            state.isLoadingAddVendor = false;
            state.error = true;
        },
        [getAllVendors.pending]: (state, action) => {
            state.isLoadingGetAllVendors = true;
            state.error = false;
        },
        [getAllVendors.fulfilled]: (state, action) => {
            state.isLoadingGetAllVendors = false;
            state.vendorList = action?.payload?.data?.vendors;
            state.vendorSchema = action?.payload?.data?.vendor_schema;
            state.error = false;
            state.vendorTags = action?.payload?.data?.vendor_tags;
        },
        [getAllVendors.rejected]: (state, action) => {
            state.isLoadingGetAllVendors = false;
            state.vendorSchema = [];
            state.error = true;
        },
        [editVendorById.pending]: (state, action) => {
            state.isLoadingEditVendorById = true;
            state.error = false;
        },
        [editVendorById.fulfilled]: (state, action) => {
            state.isLoadingEditVendorById = false;
            state.vendorDetails = action?.payload?.vendor;
            state.error = false;
        },
        [editVendorById.rejected]: (state, action) => {
            state.isLoadingEditVendorById = false;
            state.error = true;
        },
        [delVendorById.pending]: (state, action) => {
            state.isLoadingDelVendor = true;
            state.error = false;
        },
        [delVendorById.fulfilled]: (state, action) => {
            state.isLoadingDelVendor = false;
            state.error = false;
        },
        [delVendorById.rejected]: (state, action) => {
            state.isLoadingDelVendor = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.vendor.error;
export const getIsLoadingGetAllVendors = (state) =>
    state.vendor.isLoadingGetAllVendors;
export const getVendorList = (state) =>
    state.vendor.vendorList;
export const getVendorSchemaList = (state) =>
    state.vendor.vendorSchema;
export const getIsLoadingVendorSchema = (state) =>
    state.vendor.isLoadingGetVendorSchema;
export const getIsLoadingAddVendor = (state) =>
    state.vendor.isLoadingAddVendor;
export const getVendorDetails = (state) =>
    state.vendor.vendorDetails;
export const getColumnFilters = (state) => state.vendor.columnFilters;
export const getTableFilters = (state) => state.vendor.tableFilters;
export const getIsLoadingGetVendorById = (state) =>
    state.vendor.isLoadingGetVendorById;
export const getIsLoadingEditVendorById = (state) =>
    state.vendor.isLoadingEditVendorById;
export const getIsLoadingDelVendor = (state) =>
    state.vendor.isLoadingDelVendor;

export const getVendorGoals = (state) =>
    state.vendor.vendorGoals;
export const getVendorGoalsSchema = (state) =>
    state.vendor.vendorGoalsSchema;
export const getVendorProducts = (state) =>
    state.vendor.vendorProducts;
export const getVendorProductsSchema = (state) =>
    state.vendor.vendorProductsSchema;
export const getVendorProductTag = (state) =>
    state.vendor.vendorProductTag;
export const getVendorTags = (state) => state.vendor.vendorTags;
export const getWorkorderTag = (state) => state.vendor.workorderTag;
export const getVendorEmails = (state) => state.vendor.vendorEmails;
export const getVendorReports = (state) => state.vendor.vendorReports;
export const getVendorContractTemplates = (state) => state.vendor.vendorContractTemplates;
export const getVendorContracts = (state) => state.vendor.vendorContracts;

export const { storeVendorFilters, storeVendorColumnFilters } =
    vendorSlice.actions;

export default vendorSlice;

