import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { useMemo, useEffect } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
//reducers
import {
  getCurrentTheme,
} from "../redux/slices/institution";

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();

  const currentTheme = useSelector(getCurrentTheme);

  const isLight = themeMode === 'light';

  const newPalettteLight = Object.assign({}, palette.light, currentTheme?.colors?.primaryDarker ? {
    primary:{
      contrastText: "#fff",
      dark: currentTheme?.colors?.primaryDark,
      darker: currentTheme?.colors?.primaryDarker,
      light: currentTheme?.colors?.primaryLight,
      lighter: currentTheme?.colors?.primaryLighter,
      main: currentTheme?.colors?.primary
    },
    // secondary:{
    //   contrastText: "#fff",
    //   dark: currentTheme?.colors?.secondaryDark,
    //   darker: currentTheme?.colors?.secondaryDarker,
    //   light: currentTheme?.colors?.secondaryLight,
    //   lighter: currentTheme?.colors?.secondaryLighter,
    //   main: currentTheme?.colors?.secondary,
    // }
  } : {})

  const newPalettteDark = Object.assign({}, palette.dark, currentTheme?.colors?.primaryDarker ? {
    primary: {
      contrastText: "#fff",
      dark: currentTheme?.colors?.primaryDark,
      darker: currentTheme?.colors?.primaryDarker,
      light: currentTheme?.colors?.primaryLight,
      lighter: currentTheme?.colors?.primaryLighter,
      main: currentTheme?.colors?.primary
    },
    // secondary: {
    //   contrastText: "#fff",
    //   dark: currentTheme?.colors?.secondaryDark,
    //   darker: currentTheme?.colors?.secondaryDarker,
    //   light: currentTheme?.colors?.secondaryLight,
    //   lighter: currentTheme?.colors?.secondaryLighter,
    //   main: currentTheme?.colors?.secondary,
    // }
  } :{})

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? newPalettteLight : newPalettteDark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection, currentTheme]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
