// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import { useSelector } from 'react-redux';

//reducers
import { getProfile } from 'src/redux/slices/account';

// ----------------------------------------------------------------------

export default function MyAvatarNav({ profileDetails, ...other }) {
    return (
        <Avatar
            src={profileDetails?.image}
            alt={profileDetails?.first_name}
            color={profileDetails?.image ? 'default' : createAvatar(profileDetails?.first_name).color}
            {...other}
        >
            {createAvatar(profileDetails?.first_name).name}
        </Avatar>
    );
}
