import { createSlice } from "@reduxjs/toolkit";
import {
  addCalendarEvent,
  editCalendarEvent,
  delCalendarEvent,
  unLinkGoogleEvent
} from "../actions/calendarEvents";

import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddCalendarEvent: false,
  isLoadingEditCalendarEvent: false,
  isLoadingDelCalendarEvent: false,
  isLoadingUnlinkCalenderEvent: false,
};

const calendarEventsSlice = createSlice({
  name: "calendarEvents",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [addCalendarEvent.pending]: (state, action) => {
      state.isLoadingAddCalendarEvent = true;
      state.error = false;
    },
    [addCalendarEvent.fulfilled]: (state, action) => {
      state.isLoadingAddCalendarEvent = false;
      state.error = false;
    },
    [addCalendarEvent.rejected]: (state, action) => {
      state.isLoadingAddCalendarEvent = false;
      state.error = true;
    },//unLinkGoogleEvent
    [unLinkGoogleEvent.pending]: (state, action) => {
      state.isLoadingUnlinkCalenderEvent = true;
      state.error = false;
    },
    [unLinkGoogleEvent.fulfilled]: (state, action) => {
      state.isLoadingUnlinkCalenderEvent = false;
      state.error = false;
    },
    [unLinkGoogleEvent.rejected]: (state, action) => {
      state.isLoadingUnlinkCalenderEvent = false;
      state.error = true;
    },
    [editCalendarEvent.pending]: (state, action) => {
      state.isLoadingEditCalendarEvent = true;
      state.error = false;
    },
    [editCalendarEvent.fulfilled]: (state, action) => {
      state.isLoadingEditCalendarEvent = false;
      state.error = false;
    },
    [editCalendarEvent.rejected]: (state, action) => {
      state.isLoadingEditCalendarEvent = false;
      state.error = true;
    },
    [delCalendarEvent.pending]: (state, action) => {
      state.isLoadingDelCalendarEvent = true;
      state.error = false;
    },
    [delCalendarEvent.fulfilled]: (state, action) => {
      state.isLoadingDelCalendarEvent = false;
      state.error = false;
    },
    [delCalendarEvent.rejected]: (state, action) => {
      state.isLoadingDelCalendarEvent = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.calendarEvents.error;
export const getIsLoadingAddCalendarEvent = (state) => state.calendarEvents.isLoadingAddCalendarEvent;
export const getIsLoadingEditCalendarEvent = (state) => state.calendarEvents.isLoadingEditevent;
export const getIsLoadingDelCalendarEvent = (state) => state.calendarEvents.isLoadingDelEvent;
export const getIsLoadingUnlinkCalenderEvent = (state) => state.calendarEvents.isLoadingUnlinkCalenderEvent;


export default calendarEventsSlice;
