import { createSlice } from "@reduxjs/toolkit";
import { fileUploadMulti, fileUploadMultiCampaign, getCampaignAssetsByInstitutionId } from "../actions/fileUpload";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingfileUpload: false,
  fileUploadData: [],
  isLoadingGetCampaignAssetsByInstitutionId: false,
  campaignAssetsByInstitutionId: [],
};

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [fileUploadMulti.pending]: (state, action) => {
      state.isLoadingfileUpload = true;
      state.error = false;
    },
    [fileUploadMulti.fulfilled]: (state, action) => {
      state.isLoadingfileUpload = false;
      state.error = false;
      state.fileUploadData = action?.payload?.data;
    },
    [fileUploadMulti.rejected]: (state, action) => {
      state.isLoadingfileUpload = false;
      state.error = true;
    },
    [fileUploadMultiCampaign.pending]: (state, action) => {
      state.isLoadingfileUpload = true;
      state.error = false;
    },
    [fileUploadMultiCampaign.fulfilled]: (state, action) => {
      state.isLoadingfileUpload = false;
      state.error = false;
      state.fileUploadData = action?.payload?.data?.assets;
    },
    [fileUploadMultiCampaign.rejected]: (state, action) => {
      state.isLoadingfileUpload = false;
      state.error = true;
    },
    [getCampaignAssetsByInstitutionId.pending]: (state, action) => {
      state.isLoadingGetCampaignAssetsByInstitutionId = true;
      state.error = false;
    },
    [getCampaignAssetsByInstitutionId.fulfilled]: (state, action) => {
      state.isLoadingGetCampaignAssetsByInstitutionId = false;
      state.error = false;
      state.campaignAssetsByInstitutionId = action?.payload?.data?.assets;
    },
    [getCampaignAssetsByInstitutionId.rejected]: (state, action) => {
      state.isLoadingGetCampaignAssetsByInstitutionId = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getIsLoadingFileUpload = (state) => state.fileUpload.isLoadingfileUpload;
export const getError = (state) => state.fileUpload.error;
export const getfileUploadData = (state) => state.fileUpload.fileUploadData;
export const getIsLoadingGetCampaignAssetsByInstitutionId = (state) => state.fileUpload.isLoadingGetCampaignAssetsByInstitutionId;
export const getCampaignAssetsByInstitutionIdArray = (state) => state.fileUpload.campaignAssetsByInstitutionId;

export default fileUploadSlice;
