import { createSlice } from "@reduxjs/toolkit";
import {
  addEmail,
  getEmailById,
  getAllEmailsByInstitute,
  getEmailByModuleAndModuleId,
  getAllEmailsThreadsByInstitution,
  getEmailsThreadsByModuleId,
  sendDirectEmail
} from "../actions/email";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddEmail: false,
  isLoadingAllEmailsForInstitute: false,
  postedData: {},
  isLoadingGetEmailById: false,
  emailDetails: {},
  emailsList: [],
  isLoadingGetEmailByModuleAndModuleId: false,
  emailsForModule: [],
  isLoadingGetEmailThreads: false,
  allEmailThreads: [],
  isLoadingGetEmailThreadsModule: false,
  emailCount: 0,
  isLoadingSendDirectEmail: false,
};

const emailsSlice = createSlice({
  name: "emails",
  initialState,
  extraReducers: {
    [addEmail.pending]: (state, action) => {
      state.isLoadingAddEmail = true;
      state.error = false;
    },
    [addEmail.fulfilled]: (state, action) => {
      state.isLoadingAddEmail = false;
      state.postedData = action?.payload?.returnedData?.data;
      state.emailDetails = action?.payload?.returnedData?.data;
      state.error = false;
    },
    [addEmail.rejected]: (state, action) => {
      state.isLoadingAddEmail = false;
      state.error = true;
    },
    [getEmailById.pending]: (state, action) => {
      state.isLoadingGetEmailById = true;
      state.error = false;
    },
    [getEmailById.fulfilled]: (state, action) => {
      state.isLoadingGetEmailById = false;
      state.emailDetails = action?.payload?.data?.email;
      state.error = false;
    },
    [getEmailById.rejected]: (state, action) => {
      state.isLoadingGetEmailById = false;
      state.error = true;
    },//getEmailByModuleAndModuleId
    [getEmailByModuleAndModuleId.pending]: (state, action) => {
      state.isLoadingGetEmailByModuleAndModuleId = true;
      state.error = false;
    },
    [getEmailByModuleAndModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetEmailByModuleAndModuleId = false;
      state.emailsForModule = action?.payload?.data;
      state.error = false;
    },
    [getEmailByModuleAndModuleId.rejected]: (state, action) => {
      state.isLoadingGetEmailByModuleAndModuleId = false;
      state.error = true;
    },
    [getAllEmailsByInstitute.pending]: (state, action) => {
      state.isLoadingAllEmailsForInstitute = true;
      state.error = false;
    },
    [getAllEmailsByInstitute.fulfilled]: (state, action) => {
      state.isLoadingAllEmailsForInstitute = false;
      state.emailsList = action?.payload?.data?.emails;
      state.error = false;
    },
    [getAllEmailsByInstitute.rejected]: (state, action) => {
      state.isLoadingAllEmailsForInstitute = false;
      state.error = true;
    },//getAllEmailsThreadsByInstitution
    [getAllEmailsThreadsByInstitution.pending]: (state, action) => {
      state.isLoadingGetEmailThreads = true;
      state.error = false;
    },
    [getAllEmailsThreadsByInstitution.fulfilled]: (state, action) => {
      state.isLoadingGetEmailThreads = false;
      state.allEmailThreads = action?.payload?.data?.emails;
      state.emailCount = action?.payload?.data?.count;
      state.error = false;
    },
    [getAllEmailsThreadsByInstitution.rejected]: (state, action) => {
      state.isLoadingGetEmailThreads = false;
      state.error = true;
    },//getEmailsThreadsByModuleId
    [getEmailsThreadsByModuleId.pending]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = true;
      state.error = false;
    },
    [getEmailsThreadsByModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = false;
      state.emailsForModule = action?.payload?.data?.emails
      state.emailCount = action?.payload?.data?.count;
      state.error = false;
    },
    [getEmailsThreadsByModuleId.rejected]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = false;
      state.error = true;
    },//sendDirectEmail
    [sendDirectEmail.pending]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = true;
      state.error = false;
    },
    [sendDirectEmail.fulfilled]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = false;
      state.emailsForModule = action?.payload?.data?.emails
      state.emailCount = action?.payload?.data?.count;
      state.error = false;
    },
    [sendDirectEmail.rejected]: (state, action) => {
      state.isLoadingGetEmailThreadsModule = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.emails.error;
export const getIsLoadingAddEmail = (state) => state.emails.isLoadingAddEmail;
export const getPostedData = (state) => state.emails.postedData;
export const getIsLoadingGetEmailById = (state) =>
  state.emails.isLoadingGetEmailById;
export const getIsLoadingAllEmailsForInstitute = (state) =>
  state.emails.isLoadingAllEmailsForInstitute;
export const getEmailDetails = (state) => state.emails.emailDetails;
export const getEmailsList = (state) => state.emails.emailsList;
export const getIsLoadingEmailByModuleAndModuleId = (state) => state.emails.isLoadingGetEmailByModuleAndModuleId;
export const getEmailsForModule = (state) => state.emails.emailsForModule;
export const getIsLoadingGetEmailThreads = (state) => state.emails.isLoadingGetEmailThreads;
export const getAllEmailThreads = (state) => state.emails.allEmailThreads;
export const getIsLoadingEmailThreadsModule = (state) => state.emails.isLoadingGetEmailThreadsModule;
export const getEmailCount = (state) => state.emails.emailCount;
export const getIsLoadingSendDirectEmail = (state) => state.emails.isLoadingSendDirectEmail;
export default emailsSlice;
