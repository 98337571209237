import { createSlice } from "@reduxjs/toolkit";
import {
  getAllTags,
  getTagsByModule,
  getTagById,
  getTagByTagName,
  getTagsForCalendar
} from "../actions/tags";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingGetAllTags: false,
  isLoadingGetTagsByModule: false,
  tagsList: [],
  tagsForModules: [],
  tagDetails: {},
  isLoadingGetTagsById: false,
  isLoadingGetTagByTagName: false,
  isLoadingTagsForCalendar: false,
  publicTagDetails: {},
  tagsForCalender: [],
};

const tagSlice = createSlice({
  name: "tags",
  initialState,
  extraReducers: {
    [getAllTags.pending]: (state, action) => {
      state.isLoadingGetAllTags = true;
      state.error = false;
    },
    [getAllTags.fulfilled]: (state, action) => {
      state.isLoadingGetAllTags = false;
      state.tagsList = action?.payload?.data;
      state.error = false;
    },
    [getAllTags.rejected]: (state, action) => {
      state.isLoadingGetAllTags = false;
      state.error = true;
    },
    [getTagsByModule.pending]: (state, action) => {
      state.isLoadingGetTagsByModule = true;
      state.error = false;
    },
    [getTagsByModule.fulfilled]: (state, action) => {
      state.isLoadingGetTagsByModule = false;
      state.tagsForModules = action?.payload?.data;
      state.error = false;
    },
    [getTagsByModule.rejected]: (state, action) => {
      state.isLoadingGetTagsByModule = false;
      state.error = true;
    },
    [getTagsForCalendar.pending]: (state, action) => {
      state.isLoadingTagsForCalendar = true;
      state.error = false;
    },
    [getTagsForCalendar.fulfilled]: (state, action) => {
      state.isLoadingTagsForCalendar = false;
      state.tagsForCalender = action?.payload?.data;
      state.error = false;
    },
    [getTagsForCalendar.rejected]: (state, action) => {
      state.isLoadingTagsForCalendar = false;
      state.error = true;
    },
    [getTagById.pending]: (state, action) => {
      state.isLoadingGetTagsById = true;
      state.error = false;
    },
    [getTagById.fulfilled]: (state, action) => {
      state.isLoadingGetTagsById = false;
      state.tagDetails = action?.payload?.data;
      state.error = false;
    },
    [getTagById.rejected]: (state, action) => {
      state.isLoadingGetTagsById = false;
      state.error = true;
    },
    [getTagByTagName.pending]: (state, action) => {
      state.isLoadingGetTagByTagName = true;
      state.error = false;
    },
    [getTagByTagName.fulfilled]: (state, action) => {
      state.isLoadingGetTagByTagName = false;
      state.publicTagDetails = action?.payload?.data;
      state.error = false;
    },
    [getTagByTagName.rejected]: (state, action) => {
      state.isLoadingGetTagByTagName = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.tags.error;
export const getIsLoadingGetAllTags = (state) => state.tags.isLoadingGetAllTags;
export const getTagsList = (state) => state.tags.tagsList;

export const getIsLoadingGetTagsByModule = (state) =>
  state.tags.isLoadingGetTagsByModule;
export const getTagsForModules = (state) => state.tags.tagsForModules;
export const getTagDetails = (state) => state.tags.tagDetails;
export const getIsLoadingGetTagsById = (state) =>
  state.tags.isLoadingGetTagsById;
export const getPublicTagDetails = (state) => state.tags.publicTagDetails;
export const getIsLoadingTagsForCalendar = (state) => state.tags.isLoadingTagsForCalendar;
export const getTagsForCalender = (state) => state.tags.tagsForCalender;
export const getIsLoadingTagByTagName = (state) =>
  state.tags.isLoadingGetTagByTagName;

export default tagSlice;
