import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";
import querystring from "query-string";

export const getSalesOrderByGoalIdAndKey = createAsyncThunk(
    "salesOrder/getSalesOrderByGoalIdAndKey",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/items/tags?${querystring.stringify({
                tags: payload?.tagIds
            })}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getSalesOrderItemByGoal = createAsyncThunk(
    "salesOrder/getSalesOrderItemByGoal",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/sales_orders/goal/${payload?.id}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);

            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const addSalesOrder = createAsyncThunk(
    "salesOrder/addSalesOrder",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/sales_orders`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addSalesOrderItem = createAsyncThunk(
    "salesOrder/addSalesOrderItem",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/sales_orders/new/add-to-goal`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editSalesOrderById = createAsyncThunk(
    "salesOrder/editSalesOrderById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/sales_orders/${payload?.id}`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const deleteSalesOrderById = createAsyncThunk(
    "salesOrder/deleteSalesOrderById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/sales_orders/${payload?.itemId}/key/${payload?.itemKey}`;
            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                // body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);