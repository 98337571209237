import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "./account";

export const addContract = createAsyncThunk(
  "contracts/addContract",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/contracts`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editContractObservers = createAsyncThunk(
  "contracts/editContractObservers",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/contracts/${payload?.contractId}/observers`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editContract = createAsyncThunk(
  "contracts/editContract",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/contracts/${payload?.contractId}/signatories`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getContractById = createAsyncThunk(
  "contracts/getContractById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/contracts/${payload?.contractId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getAllContractsByInstitute = createAsyncThunk(
  "contracts/getAllContractsByInstitute",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/contracts/me/institution/${payload?.institutionId}` : `/api/contracts/institution/${payload?.institutionId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editContractSigById = createAsyncThunk(
  "contracts/editContractSigById",
  async (payload, thunkApi) => {

    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/contracts/${payload?.id}/signature`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        }
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editContractStatusById = createAsyncThunk(
  "contracts/editContractStatusById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/contracts/${payload?.id}/status`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const contractNegatiationUpload = createAsyncThunk(
  "contracts/contractNegatiationUpload",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/contracts/${payload?.id}/negotiation`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const cancelContract = createAsyncThunk(
  "contracts/cancelContract",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/contracts/${payload?.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);