import { createSlice } from "@reduxjs/toolkit";
import {
    getSalesOrderByGoalIdAndKey,
    addSalesOrder,
    editSalesOrderById,
    deleteSalesOrderById,
    addSalesOrderItem,
    getSalesOrderItemByGoal
} from "../actions/salesOrder";
import { logoutUser } from "../actions/account";


const initialState = {
    error: false,
    isLoadingGetSalesOrderbyIdAndKey: false,
    salesOrderList: [],
    isLoadingAddSalesOrder: false,
    isLoadingEditSalesOrder: false,
    isLoadingDeleteSalesOrder: false,
    isLoadingAddSalesOrderItem: false,
    isLoadingGetSalesOrderItemByGoal: false,
    saledOrderItemForGoal: [],
    selectedItemIds: []
};

const salesOrderSlice = createSlice({
    name: "salesOrder",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
        storeSelectedBudgetItemIds(state, action) {
            state.selectedItemIds = action.payload;
        },
        clearSelectedBudgetItemsIds(state, action) {
            state.selectedItemIds = []
        },
    },
    extraReducers: {
        [getSalesOrderByGoalIdAndKey.pending]: (state, action) => {
            state.isLoadingGetSalesOrderbyIdAndKey = true;
            state.error = false;
        },
        [getSalesOrderByGoalIdAndKey.fulfilled]: (state, action) => {
            state.isLoadingGetSalesOrderbyIdAndKey = false;
            state.salesOrderList = action?.payload?.data;
            state.error = false;
        },
        [getSalesOrderByGoalIdAndKey.rejected]: (state, action) => {
            state.isLoadingGetSalesOrderbyIdAndKey = false;
            state.error = true;
        },//getSalesOrderByGoal
        [getSalesOrderItemByGoal.pending]: (state, action) => {
            state.isLoadingGetSalesOrderItemByGoal = true;
            state.error = false;
        },
        [getSalesOrderItemByGoal.fulfilled]: (state, action) => {
            state.isLoadingGetSalesOrderItemByGoal = false;
            state.saledOrderItemForGoal = action?.payload?.data;
            state.error = false;
        },
        [getSalesOrderItemByGoal.rejected]: (state, action) => {
            state.isLoadingGetSalesOrderItemByGoal = false;
            state.error = true;
        },
        [addSalesOrder.pending]: (state, action) => {
            state.isLoadingAddSalesOrder = true;
            state.error = false;
        },
        [addSalesOrder.fulfilled]: (state, action) => {
            state.isLoadingAddSalesOrder = false;
            state.error = false;
        },
        [addSalesOrder.rejected]: (state, action) => {
            state.isLoadingAddSalesOrder = false;
            state.error = true;
        },
        [addSalesOrderItem.pending]: (state, action) => {
            state.isLoadingAddSalesOrderItem = true;
            state.error = false;
        },
        [addSalesOrderItem.fulfilled]: (state, action) => {
            state.isLoadingAddSalesOrderItem = false;
            state.error = false;
        },
        [addSalesOrderItem.rejected]: (state, action) => {
            state.isLoadingAddSalesOrderItem = false;
            state.error = true;
        },
        [editSalesOrderById.pending]: (state, action) => {
            state.isLoadingEditSalesOrder = true;
            state.error = false;
        },
        [editSalesOrderById.fulfilled]: (state, action) => {
            state.isLoadingEditSalesOrder = false;
            state.error = false;
        },
        [editSalesOrderById.rejected]: (state, action) => {
            state.isLoadingEditSalesOrder = false;
            state.error = true;
        },
        [deleteSalesOrderById.pending]: (state, action) => {
            state.isLoadingDeleteSalesOrder = true;
            state.error = false;
        },
        [deleteSalesOrderById.fulfilled]: (state, action) => {
            state.isLoadingDeleteSalesOrder = false;
            state.error = false;
        },
        [deleteSalesOrderById.rejected]: (state, action) => {
            state.isLoadingDeleteSalesOrder = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.salesOrder.error;
export const getIsLoadingGetSalesOrderbyIdAndKey = (state) =>
    state.salesOrder.isLoadingGetSalesOrderbyIdAndKey;
export const getIsLoadingAddSalesOrder = (state) =>
    state.salesOrder.isLoadingAddSalesOrder;
export const getIsLoadingAddSalesOrderItem = (state) =>
    state.salesOrder.isLoadingAddSalesOrderItem;
export const getIsLoadingEditSalesOrder = (state) =>
    state.salesOrder.isLoadingEditSalesOrder;
export const getIsLoadingDeleteSalesOrder = (state) =>
    state.salesOrder.isLoadingDeleteSalesOrder;
export const getSalesOrderList = (state) => state.salesOrder.salesOrderList;
export const getIsLoadingSalesOrderItemByGoal = (state) =>
    state.salesOrder.isLoadingGetSalesOrderItemByGoal;
export const getSaledOrderItemList = (state) => state.salesOrder.saledOrderItemForGoal;
export const getSelectedItemIds = (state) => state.salesOrder.selectedItemIds;


export const {
    storeSelectedBudgetItemIds,
    clearSelectedBudgetItemsIds
} = salesOrderSlice.actions;

export default salesOrderSlice;
