import { createSlice } from "@reduxjs/toolkit";
import { getContractTemplates } from "../actions/contractTemplates";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingGetContractTemp: false,
  templateList: [],
};

const contractTemplatesSlice = createSlice({
  name: "contractTemplates",
  initialState,
  extraReducers: {
    [getContractTemplates.pending]: (state, action) => {
      state.isLoadingGetContractTemp = true;
      state.error = false;
    },
    [getContractTemplates.fulfilled]: (state, action) => {
      state.isLoadingGetContractTemp = false;
      state.templateList = action?.payload?.data?.contract_templates;
      state.error = false;
    },
    [getContractTemplates.rejected]: (state, action) => {
      state.isLoadingGetContractTemp = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.contractTemplates.error;
export const getIsLoadingGetContractTemp = (state) =>
  state.contractTemplates.isLoadingGetContractTemp;
export const getTemplateList = (state) => state.contractTemplates.templateList;
export default contractTemplatesSlice;
