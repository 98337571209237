import { createSlice } from "@reduxjs/toolkit";
import { addCampaign, getCampaignsForInstitution, editCampaign, delCampaign, getCampaignById, getGoalsByCampaignId } from "../actions/campaigns";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingAddCampaign: false,
    isLoadingGetCampaigns: false,
    campaignList: [],
    isLoadingEditCampaign: false,
    isLoadingDelCampaign: false,
    isLoadingGetCampaignById: false,
    campaignDetails: {},
    isLoadingGoalsByCampaignId: false,
    goalsForCampaign: []
};

const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        toggleDropdownStatus(state) {
            state.changeDropdownStatus = !state.changeDropdownStatus;
        },
    },
    extraReducers: {
        [addCampaign.pending]: (state, action) => {
            state.isLoadingAddCampaign = true;
            state.error = false;
        },
        [addCampaign.fulfilled]: (state, action) => {
            state.isLoadingAddCampaign = false;
            state.error = false;
        },
        [addCampaign.rejected]: (state, action) => {
            state.isLoadingAddCampaign = false;
            state.error = true;
        },//getCampaignsForInstitution
        [getCampaignsForInstitution.pending]: (state, action) => {
            state.isLoadingGetCampaigns = true;
            state.error = false;
        },
        [getCampaignsForInstitution.fulfilled]: (state, action) => {
            state.isLoadingGetCampaigns = false;
            state.campaignList = action?.payload?.campaigns;
            state.error = false;
        },
        [getCampaignsForInstitution.rejected]: (state, action) => {
            state.isLoadingGetCampaigns = false;
            state.error = true;
        },//editCampaign
        [editCampaign.pending]: (state, action) => {
            state.isLoadingEditCampaign = true;
            state.error = false;
        },
        [editCampaign.fulfilled]: (state, action) => {
            state.isLoadingEditCampaign = false;
            state.error = false;
        },
        [editCampaign.rejected]: (state, action) => {
            state.isLoadingEditCampaign = false;
            state.error = true;
        },//delCampaign
        [delCampaign.pending]: (state, action) => {
            state.isLoadingDelCampaign = true;
            state.error = false;
        },
        [delCampaign.fulfilled]: (state, action) => {
            state.isLoadingDelCampaign = false;
            state.error = false;
        },
        [delCampaign.rejected]: (state, action) => {
            state.isLoadingDelCampaign = false;
            state.error = true;
        },//getCampaignById
        [getCampaignById.pending]: (state, action) => {
            state.isLoadingGetCampaignById = true;
            state.error = false;
        },
        [getCampaignById.fulfilled]: (state, action) => {
            state.isLoadingGetCampaignById = false;
            state.campaignDetails = action?.payload?.campaign;
            state.error = false;
        },
        [getCampaignById.rejected]: (state, action) => {
            state.isLoadingGetCampaignById = false;
            state.error = true;
        },//getGoalsByCampaignId
        [getGoalsByCampaignId.pending]: (state, action) => {
            state.isLoadingGoalsByCampaignId = true;
            state.error = false;
        },
        [getGoalsByCampaignId.fulfilled]: (state, action) => {
            state.isLoadingGoalsByCampaignId = false;
            state.goalsForCampaign = action?.payload?.goals;
            state.error = false;
        },
        [getGoalsByCampaignId.rejected]: (state, action) => {
            state.isLoadingGoalsByCampaignId = false;
            state.error = true;
        },

        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.campaign.error;
export const getIsLoadingAddCampaign = (state) => state.campaign.isLoadingAddCampaign;
export const getIsLoadingGetCampaigns = (state) => state.campaign.isLoadingGetCampaigns;
export const getCampaignList = (state) => state.campaign.campaignList;
export const getIsLoadingEditCampaign = (state) => state.campaign.isLoadingEditCampaign;
export const getIsLoadingDelCampaign = (state) => state.campaign.isLoadingDelCampaign;


export const getIsLoadingCampaignById = (state) => state.campaign.isLoadingGetCampaignById;
export const getCampaignDetails = (state) => state.campaign.campaignDetails;


export const getIsLoadingGoalsByCampaignId = (state) => state.campaign.isLoadingGoalsByCampaignId;
export const getGoalsForCampaign = (state) => state.campaign.goalsForCampaign;
export default campaignSlice;
