import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import { logoutUser } from "./account";

export const getAllVendorsWorkorderForInstitution = createAsyncThunk(
    "vendorWorks/getAllVendorsWorkorderForInstitution",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/vendorWorks/institution/${payload?.institutionId}/me` : `/api/vendorWorks/institution/${payload?.institutionId}`);
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getVendorWorksforGoal = createAsyncThunk(
    "vendorWorks/getVendorWorksforGoal",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/vendorWorks/goal/${payload?.id}/institution/${payload?.institutionId}/me` : `/api/vendorWorks/goal/${payload?.id}/institution/${payload?.institutionId}`);
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getVendorWorksforVendor = createAsyncThunk(
    "vendorWorks/getVendorWorksforVendor",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/vendor/${payload?.vendorId}`;
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getVendorWorkorderById = createAsyncThunk(
    "vendorWorks/getVendorWorkorderById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/vendorWorks/${payload?.id}` : `/api/vendorWorks/${payload?.id}`);
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addVendorWorks = createAsyncThunk(
    "vendorWorks/addVendorWorks",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addMultipleVendorWorks = createAsyncThunk(
    "vendorWorks/addMultipleVendorWorks",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/multiple`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editVendorWorkorderById = createAsyncThunk(
    "vendorWorks/editVendorWorkorderById",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editVendorWorkorderSchemaFields = createAsyncThunk(
    "vendorWorks/editVendorWorkorderSchemaFields",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/non-financial/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const publishVendorWorkOrder = createAsyncThunk(
    "vendorWorks/publishVendorWorkOrder",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/publish/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const deleteVendorWorkOrder = createAsyncThunk(
    "vendorWorks/deleteVendorWorkOrder",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/${payload?.id}`;
            const Authorization = composeAuth(payload?.jwt);

            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getWorkorderSchema = createAsyncThunk(
    "vendorWorks/getWorkorderSchema",
    async (payload, thunkApi) => {
        try {
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/schema/${payload?.institutionId}`;
            const Authorization = composeAuth(payload?.jwt);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getWorkorderTag = createAsyncThunk(
    "vendorWorks/getWorkorderTag",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/vendorWorks/tags/institution/${payload.institutionId}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                }
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser())
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);