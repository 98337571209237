import { createSlice } from "@reduxjs/toolkit";
import { getEmailTemplates } from "../actions/emailTemplates";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingGetEmailTemp: false,
  templateList: [],
};

const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState,
  extraReducers: {
    [getEmailTemplates.pending]: (state, action) => {
      state.isLoadingGetEmailTemp = true;
      state.error = false;
    },
    [getEmailTemplates.fulfilled]: (state, action) => {
      state.isLoadingGetEmailTemp = false;
      state.templateList = action?.payload?.data?.email_templates;
      state.error = false;
    },
    [getEmailTemplates.rejected]: (state, action) => {
      state.isLoadingGetEmailTemp = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.emailTemplates.error;
export const getIsLoadingGetEmailTemp = (state) =>
  state.emailTemplates.isLoadingGetEmailTemp;
export const getTemplateList = (state) => state.emailTemplates.templateList;
export default emailTemplatesSlice;
