import { createSlice } from "@reduxjs/toolkit";
import { getItemsByTagIds, editItemsByItemIds, deleteItemsByItemId, getAllItemsByInstitutionId } from "../actions/items";
import { logoutUser } from "../actions/account";


const initialState = {
    error: false,
    isLoadingGetItemsByTagIds: false,
    isLoadingEditItemById: false,
    isLoadingDeleteItemById: false,
    itemsForTags: [],
    isLoadingAllItemsByInstitutionId: false,
    allItems: [],
};

const itemsSlice = createSlice({
    name: "items",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
    },
    extraReducers: {
        [getItemsByTagIds.pending]: (state, action) => {
            state.isLoadingGetItemsByTagIds = true;
            state.error = false;
        },
        [getItemsByTagIds.fulfilled]: (state, action) => {
            state.isLoadingGetItemsByTagIds = false;
            state.itemsForTags = action?.payload?.data;
            state.error = false;
        },
        [getItemsByTagIds.rejected]: (state, action) => {
            state.isLoadingGetItemsByTagIds = false;
            state.error = true;
        },
        [getAllItemsByInstitutionId.pending]: (state, action) => {
            state.isLoadingAllItemsByInstitutionId = true;
            state.error = false;
        },
        [getAllItemsByInstitutionId.fulfilled]: (state, action) => {
            state.isLoadingAllItemsByInstitutionId = false;
            state.allItems = action?.payload?.data?.items;
            state.error = false;
        },
        [getAllItemsByInstitutionId.rejected]: (state, action) => {
            state.isLoadingAllItemsByInstitutionId = false;
            state.error = true;
        },
        [editItemsByItemIds.pending]: (state, action) => {
            state.isLoadingEditItemById = true;
            state.error = false;
        },
        [editItemsByItemIds.fulfilled]: (state, action) => {
            state.isLoadingEditItemById = false;
            state.error = false;
        },
        [editItemsByItemIds.rejected]: (state, action) => {
            state.isLoadingEditItemById = false;
            state.error = true;
        },
        [deleteItemsByItemId.pending]: (state, action) => {
            state.isLoadingDeleteItemById = true;
            state.error = false;
        },
        [deleteItemsByItemId.fulfilled]: (state, action) => {
            state.isLoadingDeleteItemById = false;
            state.error = false;
        },
        [deleteItemsByItemId.rejected]: (state, action) => {
            state.isLoadingDeleteItemById = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.items.error;
export const getIsLoadingGetItemsByTagIds = (state) =>
    state.items.isLoadingGetItemsByTagIds;
export const getIsLoadingEditItemById = (state) =>
    state.items.isLoadingEditItemById;
export const getItemsForTags = (state) => state.items.itemsForTags;
export const getIsLoadingDeleteItemById = (state) => state.items.isLoadingDeleteItemById;
export const getIsLoadingAllItemsByInstitutionId = (state) => state.items.isLoadingAllItemsByInstitutionId;
export const getAllItems = (state) => state.items.allItems;

export default itemsSlice;
