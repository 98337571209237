import { createSlice } from "@reduxjs/toolkit";
import {
  getInstitutionByDomainName,
  getInstitutionByIds,
} from "../actions/institution";
import { loginUserSelectInstitution, logoutUser } from "../actions/account";

const defaultColors = {
  primaryLighter: "#e1bee7",
  primaryLight: "#ba68c8",
  primary: "#673ab7",
  primaryDark: "#7b1fa2",
  primaryDarker: "#4a148c",
};

const initialState = {
  error: false,
  isLoadingInstitution: false,
  institutionDetails: {},
  userInstitutionDetails: [],
  currentTheme: {
    colors: defaultColors,
    logo: require("../../assets/images/logo.png"),
    logoLight: require("../../assets/images/logo.png"),
    miniLogo: require("../../assets/images/logo-white.png"),
    miniLogoLight: require("../../assets/images/logo-white.png"),
    favicon: require("../../assets/images/logo-white.png"),
    facilityName: "Resonance",
  },
};

const institutionSlice = createSlice({
  name: "institution",
  initialState,
  reducers: {
    clearInstitutionDetails(state) {
      state.institutionId = {};
    },
    setInstitutionDetails(state, action) {
      state.institutionDetails = action?.payload?.institution;
      state.currentTheme = {
        colors: action?.payload?.institution?.primaryColorLight
          ? {
            primaryLighter: action?.payload?.institution?.primaryColorLighter,
            primaryLight: action?.payload?.institution?.primaryColorLight,
            primary: action?.payload?.institution?.primaryColor,
            primaryDark: action?.payload?.institution?.primaryColorDark,
            primaryDarker: action?.payload?.institution?.primaryColorDarker,
          }
          : defaultColors,
        logo: action?.payload?.institution?.big_logo_dark,
        logoLight: action?.payload?.institution?.big_logo_light,
        miniLogo: action?.payload?.institution?.small_logo_dark,
        miniLogoLight: action?.payload?.institution?.small_logo_light,
        favicon: action?.payload?.institution?.favicon,
        facilityName: action?.payload?.institution?.name,
      }
    }
  },
  extraReducers: {
    [getInstitutionByDomainName.pending]: (state, action) => {
      state.isLoadingInstitution = true;
      state.error = false;
    },
    [getInstitutionByDomainName.fulfilled]: (state, action) => {
      state.isLoadingInstitution = false;
      state.institutionDetails = action?.payload?.institution;
      state.currentTheme = {
        colors: action?.payload?.institution?.primaryColorLight
          ? {
            primaryLighter: action?.payload?.institution?.primaryColorLighter,
            primaryLight: action?.payload?.institution?.primaryColorLight,
            primary: action?.payload?.institution?.primaryColor,
            primaryDark: action?.payload?.institution?.primaryColorDark,
            primaryDarker: action?.payload?.institution?.primaryColorDarker,
          }
          : defaultColors,
        logo: action?.payload?.institution?.big_logo_dark,
        logoLight: action?.payload?.institution?.big_logo_light,
        miniLogo: action?.payload?.institution?.small_logo_dark,
        miniLogoLight: action?.payload?.institution?.small_logo_light,
        favicon: action?.payload?.institution?.favicon,
        facilityName: action?.payload?.institution?.name,
      };
      state.error = false;
    },
    [getInstitutionByDomainName.rejected]: (state, action) => {
      state.isLoadingInstitution = false;
      state.currentTheme = {
        colors: defaultColors,
        logo: require("../../assets/images/logo.png"),
        logoLight: require("../../assets/images/logo.png"),
        miniLogo: require("../../assets/images/logo-white.png"),
        miniLogoLight: require("../../assets/images/logo-white.png"),
        favicon: require("../../assets/images/logo-white.png"),
        facilityName: "Resonance",
      };
      state.error = true;
    },
    [getInstitutionByIds.pending]: (state, action) => {
      state.isLoadingInstitution = true;
      state.error = false;
    },
    [getInstitutionByIds.fulfilled]: (state, action) => {
      state.isLoadingInstitution = false;
      state.userInstitutionDetails = action?.payload?.institutions;
      if (action?.payload?.institutions && action?.payload?.institutions?.length === 1) {
        const institutionTemp = action?.payload?.institutions?.[0]
        state.institutionDetails = institutionTemp
        state.currentTheme = {
          colors: institutionTemp?.primaryColorLight
            ? {
              primaryLighter: institutionTemp?.primaryColorLighter,
              primaryLight: institutionTemp?.primaryColorLight,
              primary: institutionTemp?.primaryColor,
              primaryDark: institutionTemp?.primaryColorDark,
              primaryDarker: institutionTemp?.primaryColorDarker,
            }
            : defaultColors,
          logo: institutionTemp?.big_logo_dark,
          logoLight: institutionTemp?.big_logo_light,
          miniLogo: institutionTemp?.small_logo_dark,
          miniLogoLight: institutionTemp?.small_logo_light,
          favicon: institutionTemp?.favicon,
          facilityName: institutionTemp?.name,
        };
      }
      state.error = false;
    },
    [loginUserSelectInstitution.fulfilled]: (state, action) => {
      if (action?.payload?.institutionDetails) {
        const institutionTemp = action?.payload?.institutionDetails
        state.institutionDetails = institutionTemp
        state.currentTheme = {
          colors: institutionTemp?.primaryColorLight
            ? {
              primaryLighter: institutionTemp?.primaryColorLighter,
              primaryLight: institutionTemp?.primaryColorLight,
              primary: institutionTemp?.primaryColor,
              primaryDark: institutionTemp?.primaryColorDark,
              primaryDarker: institutionTemp?.primaryColorDarker,
            }
            : defaultColors,
          logo: institutionTemp?.big_logo_dark,
          logoLight: institutionTemp?.big_logo_light,
          miniLogo: institutionTemp?.small_logo_dark,
          miniLogoLight: institutionTemp?.small_logo_light,
          favicon: institutionTemp?.favicon,
          facilityName: institutionTemp?.name,
        };
      }
    },
    [getInstitutionByIds.rejected]: (state, action) => {
      state.isLoadingInstitution = false;
      state.currentTheme = {
        colors: defaultColors,
        logo: require("../../assets/images/logo.png"),
        logoLight: require("../../assets/images/logo.png"),
        miniLogo: require("../../assets/images/logo-white.png"),
        miniLogoLight: require("../../assets/images/logo-white.png"),
        favicon: require("../../assets/images/logo-white.png"),
        facilityName: "Resonance",
      };
      state.error = true;
    },
  },
  [logoutUser.fulfilled]: (state) => {
    return initialState;
  },
  [logoutUser.rejected]: (state) => {
    return initialState;
  },
});

export const getError = (state) => state.institution.error;
export const getIsLoadingInstitution = (state) =>
  state.institution.isLoadingInstitution;
export const getInstitutionDetails = (state) =>
  state.institution.institutionDetails;
export const getUserInstitutionDetails = (state) =>
  state.institution.userInstitutionDetails;
export const getCurrentTheme = (state) => state.institution.currentTheme;

export const {
  clearInstitutionDetails,
  setInstitutionDetails
} = institutionSlice.actions;

export default institutionSlice;
