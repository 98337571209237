import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from "./account";



export const addAdminUser = createAsyncThunk(
  "users/addAdminUser",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + "/api/profiles/admin";

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);





export const deleteUserById = createAsyncThunk(
  "users/deleteUserById",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/user/${payload?.id}`;

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
