import { createSlice } from "@reduxjs/toolkit";
import { getSecurityDepositByGoal } from "../actions/securityDeposit";
import { logoutUser } from "../actions/account";


const initialState = {
    error: false,
    isLoadingGetSecurityDepositByGoal: false
};

const securityDepositSlice = createSlice({
    name: "securityDeposit",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
    },
    extraReducers: {
        //getSecurityDepositByGoal
        [getSecurityDepositByGoal.pending]: (state, action) => {
            state.isLoadingGetSecurityDepositByGoal = true;
            state.error = false;
        },
        [getSecurityDepositByGoal.fulfilled]: (state, action) => {
            state.isLoadingGetSecurityDepositByGoal = false;
            state.salesOrderList = action?.payload?.data;
            state.error = false;
        },
        [getSecurityDepositByGoal.rejected]: (state, action) => {
            state.isLoadingGetSecurityDepositByGoal = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.securityDeposit.error;
export const getIsLoadingSecurityDepositByGoal = (state) => state.securityDeposit.isLoadingGetSecurityDepositByGoal;


export default securityDepositSlice;
