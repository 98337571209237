import { createSlice } from "@reduxjs/toolkit";
import {
  addLocationGroup,
  getLocationGroups,
  editLocationGroup,
  delLocationGroup,
} from "../actions/locationGroups";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddLocationGroup: false,
  isLoadingGetLocationGroups: false,
  isLoadingEditLocationGroups: false,
  isLoadingDelLocationGroup: false,
  locationGroupsList: [],
};

const locationGroupSlice = createSlice({
  name: "locationGroup",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [addLocationGroup.pending]: (state, action) => {
      state.isLoadingAddLocationGroup = true;
      state.error = false;
    },
    [addLocationGroup.fulfilled]: (state, action) => {
      state.isLoadingAddLocationGroup = false;
      state.error = false;
    },
    [addLocationGroup.rejected]: (state, action) => {
      state.isLoadingAddLocationGroup = false;
      state.error = true;
    },
    [getLocationGroups.pending]: (state, action) => {
      state.isLoadingGetLocationGroups = true;
      state.error = false;
    },
    [getLocationGroups.fulfilled]: (state, action) => {
      state.isLoadingGetLocationGroups = false;
      state.locationGroupsList = action?.payload?.data?.groups;
      state.error = false;
    },
    [getLocationGroups.rejected]: (state, action) => {
      state.isLoadingGetLocationGroups = false;
      state.error = true;
    },
    [editLocationGroup.pending]: (state, action) => {
      state.isLoadingEditLocationGroups = true;
      state.error = false;
    },
    [editLocationGroup.fulfilled]: (state, action) => {
      state.isLoadingEditLocationGroups = false;
      state.error = false;
    },
    [editLocationGroup.rejected]: (state, action) => {
      state.isLoadingEditLocationGroups = false;
      state.error = true;
    },
    [delLocationGroup.pending]: (state, action) => {
      state.isLoadingDelLocationGroup = true;
      state.error = false;
    },
    [delLocationGroup.fulfilled]: (state, action) => {
      state.isLoadingDelLocationGroup = false;
      state.error = false;
    },
    [delLocationGroup.rejected]: (state, action) => {
      state.isLoadingDelLocationGroup = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state?.locationGroup?.error;
export const getIsLoadingAddLocationGroup = (state) => state?.locationGroup?.isLoadingAddLocationGroup;
export const getIsLoadingGetLocationGroups = (state) => state?.locationGroup?.isLoadingGetLocationGroups;
export const getIsLoadingEditLocationGroups = (state) => state?.locationGroup?.isLoadingEditLocationGroups;
export const getIsLoadingDelLocationGroup = (state) => state?.locationGroup?.isLoadingDelLocationGroup;
export const getLocationGroupsList = (state) => state?.locationGroup?.locationGroupsList;

export default locationGroupSlice;
