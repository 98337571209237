import { createSlice } from "@reduxjs/toolkit";
import {
    createDonation,
    getDonationById,
    getDonationsByModuleId,
    getAllDonationsForInstitution,
    editDonationById,
    publishDonation,
    payDonationById,
    cancelDonationById
} from "../actions/donations";
import { getClientById } from "../actions/clients";
import { getProfileById } from "../actions/profile";
import { getGoalByIdLazy } from "../actions/goals";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingCreateDonation: false,
    donationData: {},
    isLoadingGetDonationById: false,
    isLoadingGetDonationByModuleId: false,
    donationsList: [],
    isLoadingAllDonationsForInstitution: false,
    allDonationsList: [],
    isLoadingEditDonations: false,
    isLoadingPublishDonation: false,
    isLoadingPayDonation: false,
    isLoadingCancelDonation: false,
    donationTag: {}
};

const donationSlice = createSlice({
    name: "donation",
    initialState,
    reducers: {
    },
    extraReducers: {
        //createDonation
        [createDonation.pending]: (state, action) => {
            state.isLoadingCreateDonation = true;
            state.error = false;
        },
        [createDonation.fulfilled]: (state, action) => {
            state.isLoadingCreateDonation = false;
            state.donationData = action?.payload
            state.error = false;
        },
        [createDonation.rejected]: (state, action) => {
            state.isLoadingCreateDonation = false;
            state.error = true;
        },//getDonationById
        [getDonationById.pending]: (state, action) => {
            state.isLoadingGetDonationById = true;
            state.error = false;
        },
        [getDonationById.fulfilled]: (state, action) => {
            state.isLoadingGetDonationById = false;
            state.donationData = action?.payload?.donation
            state.donationTag = action?.payload?.tag
            state.error = false;
        },
        [getDonationById.rejected]: (state, action) => {
            state.isLoadingGetDonationById = false;
            state.error = true;
        },//getDonationsByModuleId
        [getDonationsByModuleId.pending]: (state, action) => {
            state.isLoadingGetDonationByModuleId = true;
            state.error = false;
        },
        [getDonationsByModuleId.fulfilled]: (state, action) => {
            state.isLoadingGetDonationByModuleId = false;
            state.donationsList = action?.payload?.donations
            state.donationTag = action?.payload?.tag
            state.error = false;
        },
        [getDonationsByModuleId.rejected]: (state, action) => {
            state.isLoadingGetDonationByModuleId = false;
            state.error = true;
        },//getAllDonationsForInstitution
        [getAllDonationsForInstitution.pending]: (state, action) => {
            state.isLoadingAllDonationsForInstitution = true;
            state.error = false;
        },
        [getAllDonationsForInstitution.fulfilled]: (state, action) => {
            state.isLoadingAllDonationsForInstitution = false;
            state.donationsList = action?.payload?.donations
            state.donationTag = action?.payload?.tag
            state.error = false;
        },
        [getAllDonationsForInstitution.rejected]: (state, action) => {
            state.isLoadingAllDonationsForInstitution = false;
            state.error = true;
        },//editDonationById
        [editDonationById.pending]: (state, action) => {
            state.isLoadingEditDonations = true;
            state.error = false;
        },
        [editDonationById.fulfilled]: (state, action) => {
            state.isLoadingEditDonations = false;
            state.donationData = action?.payload
            state.error = false;
        },
        [editDonationById.rejected]: (state, action) => {
            state.isLoadingEditDonations = false;
            state.error = true;
        },//publishDonation
        [publishDonation.pending]: (state, action) => {
            state.isLoadingPublishDonation = true;
            state.error = false;
        },
        [publishDonation.fulfilled]: (state, action) => {
            state.isLoadingPublishDonation = false;
            state.donationData = action?.payload
            state.error = false;
        },
        [publishDonation.rejected]: (state, action) => {
            state.isLoadingPublishDonation = false;
            state.error = true;
        },//payDonationById
        [payDonationById.pending]: (state, action) => {
            state.isLoadingPayDonation = true;
            state.error = false;
        },
        [payDonationById.fulfilled]: (state, action) => {
            state.isLoadingPayDonation = false;
            state.donationData = action?.payload
            state.error = false;
        },
        [payDonationById.rejected]: (state, action) => {
            state.isLoadingPayDonation = false;
            state.error = true;
        },//cancelDonationById
        [cancelDonationById.pending]: (state, action) => {
            state.isLoadingCancelDonation = true;
            state.error = false;
        },
        [cancelDonationById.fulfilled]: (state, action) => {
            state.isLoadingCancelDonation = false;
            state.donationData = action?.payload
            state.error = false;
        },
        [cancelDonationById.rejected]: (state, action) => {
            state.isLoadingCancelDonation = false;
            state.error = true;
        },//getGoalByIdLazy
        [getGoalByIdLazy.fulfilled]: (state, action) => {
            state.donationTag = action?.payload?.data?.donation_tag;
            state.error = false;
        },//getClientById
        [getClientById.fulfilled]: (state, action) => {
            state.donationTag = action?.payload?.data?.donation_tags
            state.error = false;
        },//getProfileById
        [getProfileById.fulfilled]: (state, action) => {
            state.donationTag = action?.payload?.donation_tags;
            state.error = false;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.donation.error;
export const getIsLoadingCreateDonation = (state) => state.donation.isLoadingCreateDonation;
export const getDonationData = (state) => state.donation.donationData;
export const getIsLoadingDonationById = (state) => state.donation.isLoadingGetDonationById;
export const getIsLoadingDonationByModuleId = (state) => state.donation.isLoadingGetDonationByModuleId;
export const getDonationsList = (state) => state.donation.donationsList;
export const getIsLoadingAllDonationsForInstitution = (state) => state.donation.isLoadingAllDonationsForInstitution;
export const getIsLoadingEditDonations = (state) => state.donation.isLoadingEditDonations;
export const getIsLoadingPublishDonation = (state) => state.donation.isLoadingPublishDonation;
export const getIsLoadingPayDonation = (state) => state.donation.isLoadingPayDonation;
export const getIsLoadingCancelDonation = (state) => state.donation.isLoadingCancelDonation;
export const getDonationTag = (state) => state.donation.donationTag;




export default donationSlice;
