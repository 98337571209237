import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { dispatch, useSelector } from '../redux/store';
import { useNavigate } from "react-router-dom";
// routes
import { PATH_DASHBOARD } from '../routes/paths';
//reducers
import { getAllUserInstitutionIds, getInstitutionId, getIsLoggedIn } from '../redux/slices/account'
import { getUrlOnLogin, getUserNavigationArray, storeUrlOnLogin } from '../redux/slices/navigation'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'src/helpers/utils';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoggedInPrev = usePrevious(isLoggedIn);
  const userNavigations = useSelector(getUserNavigationArray);
  const allUserInstitutionIds = useSelector(getAllUserInstitutionIds);
  const institutionId = useSelector(getInstitutionId);
  const urlOnLogin = useSelector(getUrlOnLogin);
  
  useEffect(()=>{
    if (isLoggedIn){
      if (allUserInstitutionIds && (allUserInstitutionIds?.length > 1) && !institutionId ){
        navigate("/institutions/all")
      }
      else if (userNavigations){
        navigate(urlOnLogin ? urlOnLogin : ("/" + userNavigations?.slice()?.sort((a, b) => {
          const sectionOrder = a?.section_order - b?.section_order
          if (sectionOrder === 0) {
            return a?.order - b?.order
          }
          else {
            return a?.section_order - b?.section_order
          }
        })?.[0]?.link))
      }
    }
    if(isLoggedInPrev && !isLoggedIn){
      dispatch(storeUrlOnLogin(null))
    }
  }, [userNavigations, isLoggedIn])

  if (isLoggedIn) {
    return <Navigate to={"/" + (allUserInstitutionIds && (allUserInstitutionIds?.length > 1) && !institutionId) ? "institutions/all" : urlOnLogin ? urlOnLogin : (userNavigations?.slice()?.sort((a, b) => {
        const sectionOrder = a?.section_order - b?.section_order
        if (sectionOrder === 0) {
          return a?.order - b?.order
        }
        else {
          return a?.section_order - b?.section_order
        }
      })?.[0]?.link)} />;
  }

  return <>{children}</>;
}
