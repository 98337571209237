// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  referral:{
    referrals: path(ROOTS_DASHBOARD, 'referrals'),
    referralView: (id) => path(ROOTS_DASHBOARD, `referral/view/${id}`),
    referralAdd: path(ROOTS_DASHBOARD, 'referral/add'),
    referrers: path(ROOTS_DASHBOARD, 'referrers'),
    resources: path(ROOTS_DASHBOARD, 'resources'),
    resourceView: path(ROOTS_DASHBOARD, 'resource/view'),
  },
  product:{
    products: path(ROOTS_DASHBOARD, 'products'),
    productView: path(ROOTS_DASHBOARD, 'products/view'),
    productAdd: path(ROOTS_DASHBOARD, 'product/add'),
    productedit: (id) => path(ROOTS_DASHBOARD, `product/edit/${id}`)
 
  },
  goals:{
    goals: (id) => path(ROOTS_DASHBOARD, `goals/${id}`),
  },
  account:{
    changePassword: path(ROOTS_DASHBOARD, 'changePassword')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
