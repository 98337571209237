// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import { useSelector } from 'react-redux';

//reducers
import { getProfile } from 'src/redux/slices/account';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const profile = useSelector(getProfile);
  return (
    <Avatar
      src={profile?.image}
      alt={profile?.first_name}
      color={profile?.image ? 'default' : createAvatar(profile?.first_name).color}
      {...other}
    >
      {createAvatar(profile?.first_name).name}
    </Avatar>
  );
}
