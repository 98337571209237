import { createSlice } from "@reduxjs/toolkit";
import {
  addContract,
  editContractSigById,
  editContractStatusById,
  getContractById,
  getAllContractsByInstitute,
  contractNegatiationUpload,
  editContract,
  editContractObservers,
  cancelContract
} from "../actions/contracts";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddContract: false,
  isLoadingEditContract: false,
  isLoadingEditSigById: false,
  isLoadingEditContractStatus: false,
  isLoadingAllContractsForInstitute: false,
  postedData: {},
  isLoadingGetContractById: false,
  contractDetails: {},
  contractsList: [],
  isLoadingEditContractObservers: false,
  isLoadingCancelContracts: false,
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  extraReducers: {
    [addContract.pending]: (state, action) => {
      state.isLoadingAddContract = true;
      state.error = false;
    },
    [addContract.fulfilled]: (state, action) => {
      state.isLoadingAddContract = false;
      state.postedData = action?.payload?.data;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [addContract.rejected]: (state, action) => {
      state.isLoadingAddContract = false;
      state.error = true;
    },
    [editContract.pending]: (state, action) => {
      state.isLoadingEditContract = true;
      state.error = false;
    },
    [editContract.fulfilled]: (state, action) => {
      state.isLoadingEditContract = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [editContract.rejected]: (state, action) => {
      state.isLoadingEditContract = false;
      state.error = true;
    },
    [getContractById.pending]: (state, action) => {
      state.isLoadingGetContractById = true;
      state.error = false;
    },
    [getContractById.fulfilled]: (state, action) => {
      state.isLoadingGetContractById = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [getContractById.rejected]: (state, action) => {
      state.isLoadingGetContractById = false;
      state.error = true;
    },
    [getAllContractsByInstitute.pending]: (state, action) => {
      state.isLoadingAllContractsForInstitute = true;
      state.error = false;
    },
    [getAllContractsByInstitute.fulfilled]: (state, action) => {
      state.isLoadingAllContractsForInstitute = false;
      state.contractsList = action?.payload?.data?.contracts ? action?.payload?.data?.contracts : action?.payload?.data;
      state.error = false;
    },
    [getAllContractsByInstitute.rejected]: (state, action) => {
      state.isLoadingAllContractsForInstitute = false;
      state.error = true;
    },
    [editContractSigById.pending]: (state, action) => {
      state.isLoadingEditSigById = true;
      state.error = false;
    },
    [editContractSigById.fulfilled]: (state, action) => {
      state.isLoadingEditSigById = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [editContractSigById.rejected]: (state, action) => {
      state.isLoadingEditSigById = false;
      state.error = true;
    },
    [editContractStatusById.pending]: (state, action) => {
      state.isLoadingEditContractStatus = true;
      state.error = false;
    },
    [editContractStatusById.fulfilled]: (state, action) => {
      state.isLoadingEditContractStatus = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [editContractStatusById.rejected]: (state, action) => {
      state.isLoadingEditContractStatus = false;
      state.error = true;
    },
    [contractNegatiationUpload.pending]: (state, action) => {
      state.isLoadingContractNegatiationUpload = true;
      state.error = false;
    },
    [contractNegatiationUpload.fulfilled]: (state, action) => {
      state.isLoadingContractNegatiationUpload = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [contractNegatiationUpload.rejected]: (state, action) => {
      state.isLoadingContractNegatiationUpload = false;
      state.error = true;
    },
    [editContractObservers.pending]: (state, action) => {
      state.isLoadingEditContractObservers = true;
      state.error = false;
    },
    [editContractObservers.fulfilled]: (state, action) => {
      state.isLoadingEditContractObservers = false;
      state.contractDetails = action?.payload?.data;
      state.error = false;
    },
    [editContractObservers.rejected]: (state, action) => {
      state.isLoadingEditContractObservers = false;
      state.error = true;
    },//cancelContract
    [cancelContract.pending]: (state, action) => {
      state.isLoadingCancelContracts = true;
      state.error = false;
    },
    [cancelContract.fulfilled]: (state, action) => {
      state.isLoadingCancelContracts = false;
      state.error = false;
    },
    [cancelContract.rejected]: (state, action) => {
      state.isLoadingCancelContracts = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.contracts.error;
export const getIsLoadingAddContract = (state) =>
  state.contracts.isLoadingAddContract;
export const getIsLoadingEditContract = (state) =>
  state.contracts.isLoadingEditContract;
export const getIsLoadingEditContractSig = (state) =>
  state.contracts.isLoadingEditSigById;
export const getIsLoadingEditContractStatus = (state) =>
  state.contracts.isLoadingEditContractStatus;
export const getPostedData = (state) => state.contracts.postedData;
export const getIsLoadingGetContractById = (state) =>
  state.contracts.isLoadingGetContractById;
export const getIsLoadingAllContractsForInstitute = (state) =>
  state.contracts.isLoadingAllContractsForInstitute;
export const getContractDetails = (state) => state.contracts.contractDetails;
export const getContractsList = (state) => state.contracts.contractsList;
export const getIsLoadingContractNegatiationUpload = (state) => state.contracts.isLoadingContractNegatiationUpload;
export const getIsLoadingEditContractObservers = (state) => state.contracts.isLoadingEditContractObservers;
export const getIsLoadingCancelContracts = (state) => state.contracts.isLoadingCancelContracts;

export default contractsSlice;
