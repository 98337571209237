import { createSlice } from "@reduxjs/toolkit";

//actions
import {
  loginUser,
  logoutUser,
  verifyEmailOtp,
  setPassword,
  forgotPassword,
  resetAdminPassword,
  createUser,
  addGoogleAccessToken,
  removeGoogleAccessToken,
  addOutlookAccessToken,
  removeOutlookAccessToken,
  resendLink,
  loginUserSelectInstitution
} from "../actions/account";
import { editProfileById } from "../actions/profile";

const initialState = {
  isLoadingLogin: false,
  isLoadingLoginSelectInstitution: false,
  isLoggedIn: false,
  error: false,
  status: "",
  firstName: "",
  lastName: "",
  institutionId: "",
  allUserInstitutionIds: [],
  jwt: "",
  userId: "",
  profileId: "",
  email: "",
  isLoadingVerifyEmailOtp: false,
  isLoadingSetPassword: false,
  isLoadingResetPassword: false,
  isLoadingForgotPassword: false,
  profile: {},
  profileType: "",
  userRoutePrefix: "/",
  userViewTags: [],
  userEditTags: [],
  isLoadingCreateUser: false,
  isLoadingAddGoogleAccessToken: false,
  googleCredentials: {},
  outlookToken: {},
  isLoadingRemoveGoogleAccessToken: false,
  isLoadingAddOutlookAccessToken: false,
  isLoadingRemoveOutlookAccessToken: false,
  outlookToken: {},
  isLoadingResendLink: false,
  selectedRowLength: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    clearInstitutionId(state) {
      state.institutionId = "";
    },
    setInstitutionId(state, action) {
      state.institutionId = action.payload;
    },
    setRowLength(state, action) {
      state.selectedRowLength = action.payload;
    }
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoadingLogin = true;
      state.error = false;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isLoadingLogin = false;
      state.isLoggedIn = true;
      state.jwt = action?.payload?.jwt;
      state.firstName = action?.payload?.name;
      state.userId = action?.payload?._id;
      state.profileId = action?.payload?.profile_id;
      state.email = action?.payload?.user;
      state.allUserInstitutionIds = action?.payload?.institutionId;
      state.institutionId = action?.payload?.institutionId?.length === 1 ? action?.payload?.institutionId?.[0] : undefined;
      state.profile = action?.payload?.profile
      state.googleCredentials = action?.payload?.googleToken;
      state.outlookToken = action?.payload?.outlookToken;
      state.profileType = action?.payload?.profile?.type
      state.userRoutePrefix = action?.payload?.profile?.type === "user" ? "/me/" : "/"
      state.userViewTags = action?.payload?.profile?.view_tag ? action?.payload?.profile?.view_tag : []
      state.userEditTags = action?.payload?.profile?.edit_tag ? action?.payload?.profile?.edit_tag : []
    },
    [loginUser.rejected]: (state) => {
      state.isLoadingLogin = false;
      state.error = true;
    },
    [loginUserSelectInstitution.pending]: (state) => {
      state.isLoadingLoginSelectInstitution = true;
      state.error = false;
    },
    [loginUserSelectInstitution.fulfilled]: (state, action) => {
      state.isLoadingLoginSelectInstitution = false;
      state.isLoggedIn = true;
      state.jwt = action?.payload?.jwt;
      state.firstName = action?.payload?.name;
      state.userId = action?.payload?._id;
      state.profileId = action?.payload?.profile_id;
      state.email = action?.payload?.user;
      state.institutionId = action?.payload?.institutionId;
      state.profile = action?.payload?.profile
      state.googleCredentials = action?.payload?.googleToken;
      state.outlookToken = action?.payload?.outlookToken;
      state.profileType = action?.payload?.profile?.type
      state.userRoutePrefix = action?.payload?.profile?.type === "user" ? "/me/" : "/"
      state.userViewTags = action?.payload?.profile?.view_tag ? action?.payload?.profile?.view_tag : []
      state.userEditTags = action?.payload?.profile?.edit_tag ? action?.payload?.profile?.edit_tag : []
      state.error = false;
    },
    [loginUserSelectInstitution.rejected]: (state) => {
      state.isLoadingLoginSelectInstitution = false;
      state.error = true;
    },
    [verifyEmailOtp.pending]: (state) => {
      state.isLoadingVerifyEmailOtp = true;
      state.error = false;
    },
    [verifyEmailOtp.fulfilled]: (state, action) => {
      state.isLoadingVerifyEmailOtp = false;
      state.isLoggedIn = true;
      state.error = false;
      state.jwt = action?.payload?.jwt;
      state.firstName = action?.payload?.name;
      state.userId = action?.payload?._id;
      state.profileId = action?.payload?.profile_id;
      state.email = action?.payload?.user;
      state.allUserInstitutionIds = action?.payload?.institutionId;
      state.institutionId = action?.payload?.institutionId?.length === 1 ? action?.payload?.institutionId?.[0] : undefined;
      state.googleCredentials = action?.payload?.googleToken;
      state.outlookToken = action?.payload?.outlookToken;
      state.profile = action?.payload?.profile
      state.profileType = action?.payload?.profile?.type
      state.userRoutePrefix = action?.payload?.profile?.type === "user" ? "/me/" : "/"
      state.userViewTags = action?.payload?.profile?.view_tag ? action?.payload?.profile?.view_tag : []
      state.userEditTags = action?.payload?.profile?.edit_tag ? action?.payload?.profile?.edit_tag : []
    },
    [verifyEmailOtp.rejected]: (state) => {
      state.isLoadingVerifyEmailOtp = false;
      state.error = true;
      state.isLoggedIn = initialState.isLoggedIn;
      state.jwt = initialState.jwt;
      state.firstName = initialState.firstName;
      state.profileId = initialState.profileId;
      state.userId = initialState.userId;
      state.email = initialState.email;
      state.institutionId = initialState.institutionId;
    },
    [setPassword.pending]: (state) => {
      state.isLoadingSetPassword = true;
      state.error = false;
    },
    [setPassword.fulfilled]: (state, action) => {
      state.isLoadingSetPassword = false;
      state.error = false;
    },
    [setPassword.rejected]: (state) => {
      state.isLoadingSetPassword = false;
      state.error = true;
    },
    [resetAdminPassword.pending]: (state) => {
      state.isLoadingResetPassword = true;
      state.error = false;
    },
    [resetAdminPassword.fulfilled]: (state, action) => {
      state.isLoadingResetPassword = false;
      state.error = false;
    },
    [resetAdminPassword.rejected]: (state) => {
      state.isLoadingResetPassword = false;
      state.error = true;
    },
    [createUser.pending]: (state) => {
      state.isLoadingCreateUser = true;
      state.error = false;
    },
    [createUser.fulfilled]: (state, action) => {
      state.isLoadingCreateUser = false;
      state.error = false;
    },
    [createUser.rejected]: (state) => {
      state.isLoadingCreateUser = false;
      state.error = true;
    },
    [logoutUser.pending]: (state) => {
      state.isLoadingLogin = true;
      state.error = false;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
    [forgotPassword.pending]: (state) => {
      state.isLoadingForgotPassword = true;
      state.error = false;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isLoadingForgotPassword = false;
      state.error = false;
    },
    [forgotPassword.rejected]: (state) => {
      state.isLoadingForgotPassword = false;
      state.error = true;
    },//resendLink
    [resendLink.pending]: (state) => {
      state.isLoadingResendLink = true;
      state.error = false;
    },
    [resendLink.fulfilled]: (state) => {
      state.isLoadingResendLink = false;
      state.error = false;
    },
    [resendLink.rejected]: (state) => {
      state.isLoadingResendLink = false;
      state.error = true;
    },
    [addGoogleAccessToken.pending]: (state) => {
      state.isLoadingAddGoogleAccessToken = true;
      state.error = false;
    },
    [addGoogleAccessToken.fulfilled]: (state, action) => {
      state.isLoadingAddGoogleAccessToken = false;
      state.googleCredentials = action?.payload?.data?.googleToken;
      state.outlookToken = {};
      state.error = false;
    },
    [addGoogleAccessToken.rejected]: (state) => {
      state.isLoadingAddGoogleAccessToken = false;
      state.error = true;
    },
    [removeGoogleAccessToken.pending]: (state) => {
      state.isLoadingRemoveGoogleAccessToken = true;
      state.error = false;
    },
    [removeGoogleAccessToken.fulfilled]: (state, action) => {
      state.isLoadingRemoveGoogleAccessToken = false;
      state.googleCredentials = {};
      state.error = false;
    },
    [removeGoogleAccessToken.rejected]: (state) => {
      state.isLoadingRemoveGoogleAccessToken = false;
      state.error = true;
    },

    [addOutlookAccessToken.pending]: (state) => {
      state.isLoadingAddOutlookAccessToken = true;
      state.error = false;
    },
    [addOutlookAccessToken.fulfilled]: (state, action) => {
      state.isLoadingAddOutlookAccessToken = false;
      state.outlookToken = action?.payload?.data?.outlookToken;
      state.googleCredentials = {};
      state.error = false;
    },
    [addOutlookAccessToken.rejected]: (state) => {
      state.isLoadingAddOutlookAccessToken = false;
      state.error = true;
    },
    [removeOutlookAccessToken.pending]: (state) => {
      state.isLoadingRemoveOutlookAccessToken = true;
      state.error = false;
    },
    [removeOutlookAccessToken.fulfilled]: (state, action) => {
      state.isLoadingRemoveOutlookAccessToken = false;
      state.outlookToken = {};
      state.error = false;
    },
    [removeOutlookAccessToken.rejected]: (state) => {
      state.isLoadingRemoveOutlookAccessToken = false;
      state.error = true;
    },

    [editProfileById.fulfilled]: (state, action) => {
      if (state.profileId === action?.payload?.profile?._id) {
        state.profile = action?.payload?.profile
        state.profileType = action?.payload?.profile?.type
        state.userRoutePrefix = action?.payload?.profile?.type === "user" ? "/me/" : "/"
        state.userViewTags = action?.payload?.profile?.view_tag ? action?.payload?.profile?.view_tag : []
        state.userEditTags = action?.payload?.profile?.edit_tag ? action?.payload?.profile?.edit_tag : []
      }
    },
  },
});

export const getIsLoggedIn = (state) => state.account.isLoggedIn;
export const getJwt = (state) => state.account.jwt;
export const getAllUserInstitutionIds = (state) => state.account.allUserInstitutionIds;
export const getInstitutionId = (state) => state.account.institutionId;
export const getIsLoadingLogin = (state) => state.account.isLoadingLogin;
export const getIsLoadingLoginSelectInstitution = (state) => state.account.isLoadingLoginSelectInstitution;
export const getFirstName = (state) => state.account.firstName;
export const getEmail = (state) => state.account.email;
export const getUserId = (state) => state.account.userId;
export const getError = (state) => state.account.error;
export const getProfileId = (state) => state.account.profileId;
export const getProfile = (state) => state.account.profile;
export const getProfileType = (state) => state.account.profileType;
export const getUserRoutePrefix = (state) => state.account.userRoutePrefix;
export const getUserViewTags = (state) => state.account.userViewTags;
export const getUserEditTags = (state) => state.account.userEditTags;
export const getIsLoadingVerifyEmailOtp = (state) =>
  state.account.isLoadingVerifyEmailOtp;
export const getIsLoadingSetPassword = (state) =>
  state.account.isLoadingSetPassword;
export const getIsLoadingResetPassword = (state) =>
  state.account.isLoadingResetPassword;
export const getIsLoadingForgotPassword = (state) =>
  state.account.isLoadingForgotPassword;
export const getIsLoadingCreateUser = (state) =>
  state.account.isLoadingCreateUser;
export const getGoogleCredentials = (state) =>
  state.account.googleCredentials;
export const getOutlookToken = (state) =>
  state.account.outlookToken;
export const getIsLoadingAddGoogleAccessToken = (state) =>
  state.account.isLoadingAddGoogleAccessToken;
export const getIsLoadingRemoveGoogleAccessToken = (state) =>
  state.account.isLoadingRemoveGoogleAccessToken;
export const getIsLoadingAddOutlookAccessToken = (state) =>
  state.account.isLoadingAddOutlookAccessToken;
export const getIsLoadingRemoveOutlookAccessToken = (state) =>
  state.account.isLoadingRemoveOutlookAccessToken;
export const getIsLoadingResendLink = (state) =>
  state.account.isLoadingResendLink;
export const getSelectedRowLength = (state) =>
  state.account.selectedRowLength;

export const {
  clearInstitutionId,
  setInstitutionId,
  setRowLength
} = accountSlice.actions;

export default accountSlice;
