import { createSlice } from "@reduxjs/toolkit";
import { getEnquirySchema, submitEnquiryForm, getEnquiries, getEnquiryById, editEnquiryById, editEnquiryStatusById, delEnquiryById } from "../actions/enquiry";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  referralStatus: "",
  isLoadingEnquirySubmission: false,
  isLoadingGetEnquiries: false,
  isLoadingEditEnquiry: false,
  isLoadingEditEnquiryStatus: false,
  isLoadingDelEnquiry: false,
  enquiries: [],
  enquiry: {},
  isLoadingEnquirySchema:false,
  enquirySchema:[]
};

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [getEnquirySchema.pending]: (state, action) => {
      state.isLoadingEnquirySchema = true;
      state.error = false;
    },
    [getEnquirySchema.fulfilled]: (state, action) => {
      state.isLoadingEnquirySchema = false;
      state.enquirySchema = action?.payload?.data;
      state.error = false;
    },
    [getEnquirySchema.rejected]: (state, action) => {
      state.isLoadingEnquirySchema = false;
      state.error = true;
    },
    [submitEnquiryForm.pending]: (state, action) => {
      state.isLoadingEnquirySubmission = true;
      state.error = false;
    },
    [submitEnquiryForm.fulfilled]: (state, action) => {
      state.isLoadingEnquirySubmission = false;
      state.error = false;
    },
    [submitEnquiryForm.rejected]: (state, action) => {
      state.isLoadingEnquirySubmission = false;
      state.error = true;
    },
    [getEnquiries.pending]: (state, action) => {
      state.isLoadingGetEnquiries = true;
      state.error = false;
    },
    [getEnquiries.fulfilled]: (state, action) => {
      state.isLoadingGetEnquiries = false;
      state.enquiries = action?.payload?.data?.inquiries
        ? action?.payload?.data?.inquiries
        : [];
      state.error = false;
    },
    [getEnquiries.rejected]: (state, action) => {
      state.isLoadingGetEnquiries = false;
      state.error = true;
    },
    [getEnquiryById.pending]: (state, action) => {
      state.isLoadingGetEnquiries = true;
      state.error = false;
    },
    [getEnquiryById.fulfilled]: (state, action) => {
      state.isLoadingGetEnquiries = false;
      state.enquiry = action?.payload?.data?.inquiry
        ? action?.payload?.data?.inquiry
        : {};
      state.error = false;
    },
    [getEnquiryById.rejected]: (state, action) => {
      state.isLoadingGetEnquiries = false;
      state.error = true;
    },
    [editEnquiryById.pending]: (state, action) => {
      state.isLoadingEditEnquiry = true;
      state.error = false;
    },
    [editEnquiryById.fulfilled]: (state, action) => {
      state.isLoadingEditEnquiry = false;
      state.enquiry = action?.payload?.inquiry ? action?.payload?.inquiry : {};
      state.error = false;
    },
    [editEnquiryById.rejected]: (state, action) => {
      state.isLoadingEditEnquiry = false;
      state.error = true;
    },
    [editEnquiryStatusById.pending]: (state, action) => {
      state.isLoadingEditEnquiryStatus = true;
      state.error = false;
    },
    [editEnquiryStatusById.fulfilled]: (state, action) => {
      state.isLoadingEditEnquiryStatus = false;
      state.enquiry = action?.payload?.data?.inquiry
        ? action?.payload?.data?.inquiry
        : {};
      state.error = false;
    },
    [editEnquiryStatusById.rejected]: (state, action) => {
      state.isLoadingEditEnquiryStatus = false;
      state.error = true;
    },
    [delEnquiryById.pending]: (state, action) => {
      state.isLoadingDelEnquiry = true;
      state.error = false;
    },
    [delEnquiryById.fulfilled]: (state, action) => {
      state.isLoadingDelEnquiry = false;
      state.error = false;
    },
    [delEnquiryById.rejected]: (state, action) => {
      state.isLoadingDelEnquiry = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.enquiry.error;
export const getReferralStatus = (state) => state.enquiry.referralStatus;
export const getIsLoadingEnquirySubmission = (state) =>
  state.enquiry.isLoadingEnquirySubmission;
export const getIsLoadingGetEnquiries = (state) =>
  state.enquiry.isLoadingGetEnquiries;
export const getIsLoadingDelEnquiries = (state) =>
  state.enquiry.isLoadingDelEnquiry;
export const getEnquiriesArray = (state) => state.enquiry.enquiries;
export const getEnquiry = (state) => state.enquiry.enquiry;
export const getIsLoadingEditEnquiry = (state) =>
  state.enquiry.isLoadingEditEnquiry;
export const getIsLoadingEditEnquiryStatus = (state) =>
  state.enquiry.isLoadingEditEnquiryStatus;
export const getIsLoadingEnquirySchema = (state) =>
  state.enquiry.isLoadingEnquirySchema;
export const getEnquirySchemaArray = (state) =>
  state.enquiry.enquirySchema;


export default enquirySlice;
