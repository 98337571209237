import { createSlice } from "@reduxjs/toolkit";
import { createEmailBurst, getEmailBurstByCampaignId, getEmailBurstByEmailBurstId, editEmailBurst, deleteteEmailBurst, getEmailDeliveryReports } from "../actions/emailBurst";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingCreateEmailBurst: false,
    emailBurstData: {},
    isLoadingEmailBurstByCampaignId: false,
    emailBurstForCampaign: [],
    isLoadingEmailBurstById: false,
    isLoadingEditEmailBurst: false,
    isLoadingDelEmailBurst: false,
    isLoadingEmailDelReports: false,
    emailDeliveryReport: []
};

const emailBurstSlice = createSlice({
    name: "emailBurst",
    initialState,
    reducers: {
        clearEmailBurstData(state) {
            state.emailBurstData = {};
        }
    },
    extraReducers: {
        //createEmailBurst
        [createEmailBurst.pending]: (state, action) => {
            state.isLoadingCreateEmailBurst = true;
            state.error = false;
        },
        [createEmailBurst.fulfilled]: (state, action) => {
            state.isLoadingCreateEmailBurst = false;
            state.emailBurstData = action?.payload
            state.error = false;
        },
        [createEmailBurst.rejected]: (state, action) => {
            state.isLoadingCreateEmailBurst = false;
            state.error = true;
        },//editEmailBurst
        [editEmailBurst.pending]: (state, action) => {
            state.isLoadingEditEmailBurst = true;
            state.error = false;
        },
        [editEmailBurst.fulfilled]: (state, action) => {
            state.isLoadingEditEmailBurst = false;
            state.emailBurstData = action?.payload
            state.error = false;
        },
        [editEmailBurst.rejected]: (state, action) => {
            state.isLoadingEditEmailBurst = false;
            state.error = true;
        },//getEmailBurstByCampaignId
        [getEmailBurstByCampaignId.pending]: (state, action) => {
            state.isLoadingEmailBurstByCampaignId = true;
            state.error = false;
        },
        [getEmailBurstByCampaignId.fulfilled]: (state, action) => {
            state.isLoadingEmailBurstByCampaignId = false;
            state.emailBurstForCampaign = action?.payload
            state.error = false;
        },
        [getEmailBurstByCampaignId.rejected]: (state, action) => {
            state.isLoadingEmailBurstByCampaignId = false;
            state.error = true;
        },//getEmailDeliveryReports
        [getEmailDeliveryReports.pending]: (state, action) => {
            state.isLoadingEmailDelReports = true;
            state.error = false;
        },
        [getEmailDeliveryReports.fulfilled]: (state, action) => {
            state.isLoadingEmailDelReports = false;
            state.emailDeliveryReport = action?.payload
            state.error = false;
        },
        [getEmailDeliveryReports.rejected]: (state, action) => {
            state.isLoadingEmailDelReports = false;
            state.error = true;
        },//getEmailBurstByEmailBurstId
        [getEmailBurstByEmailBurstId.pending]: (state, action) => {
            state.isLoadingEmailBurstById = true;
            state.error = false;
        },
        [getEmailBurstByEmailBurstId.fulfilled]: (state, action) => {
            state.isLoadingEmailBurstById = false;
            state.emailBurstData = action?.payload
            state.error = false;
        },
        [getEmailBurstByEmailBurstId.rejected]: (state, action) => {
            state.isLoadingEmailBurstById = false;
            state.error = true;
        },//deleteteEmailBurst
        [deleteteEmailBurst.pending]: (state, action) => {
            state.isLoadingDelEmailBurst = true;
            state.error = false;
        },
        [deleteteEmailBurst.fulfilled]: (state, action) => {
            state.isLoadingDelEmailBurst = false;
            state.error = false;
        },
        [deleteteEmailBurst.rejected]: (state, action) => {
            state.isLoadingDelEmailBurst = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.emailBurst.error;
export const getIsLoadingCreateEmailBurst = (state) => state.emailBurst.isLoadingCreateEmailBurst;
export const getEmailBurstData = (state) => state.emailBurst.emailBurstData;


export const getIsLoadingEmailBurstByCampaignId = (state) => state.emailBurst.isLoadingEmailBurstByCampaignId;
export const getEmailBurstForCampaign = (state) => state.emailBurst.emailBurstForCampaign;
export const getIsLoadingEmailBurstById = (state) => state.emailBurst.isLoadingEmailBurstById;
export const getIsLoadingEditEmailBurst = (state) => state.emailBurst.isLoadingEditEmailBurst;
export const getIsLoadingDelEmailBurst = (state) => state.emailBurst.isLoadingDelEmailBurst;
export const getIsLoadingEmailDelReports = (state) => state.emailBurst.isLoadingEmailDelReports;
export const getEmailDeliveryReport = (state) => state.emailBurst.emailDeliveryReport;




export const {
    clearEmailBurstData
} = emailBurstSlice.actions;

export default emailBurstSlice;
