import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";

export const fileUploadMulti = createAsyncThunk(
  "fileUpload/fileUploadMulti",
  async (payload, thunkApi) => {
    try {
      let resArray = []
      const url = process.env.REACT_APP_SERVER + "/api/s3/multiple/public"
      for (const pair of payload.entries()) {
        let formData = new FormData();
        formData.append(pair[0], pair[1], pair[1]?.name);
        const resTemp = await fetch(
          url,
          {
            method: "POST",
            headers: {
              Accept: "application/*",
            },
            body: formData,
          }
        );
        const response = await resTemp.json();
        const validateData = checkStatus(response);
        resArray = Array.from(payload.keys()).length > 1 ? resArray?.concat(validateData?.data) : validateData?.data
      }
      return {data:resArray};
    } catch (err) {
      if (err instanceof AuthError) {
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fileUploadMultiCampaign = createAsyncThunk(
  "fileUpload/fileUploadMultiCampaign",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + "/api/assets"
      const res = await fetch(
         url,
        {
          method: "POST",
          headers: {
            Accept: "application/*",
            Authorization
          },
          body: payload?.formData,
        }
      );

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getCampaignAssetsByInstitutionId = createAsyncThunk(
  "fileUpload/getCampaignAssetsByInstitutionId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/assets/institution/${payload?.institutionId}`
      const res = await fetch(
         url,
        {
          method: "GET",
          headers: {
            Accept: "application/*",
            Authorization
          }
        }
      );

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
