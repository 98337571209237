import { createSlice } from "@reduxjs/toolkit";
import _ from "underscore"
import {
  getAllNotifications,
  getModuleNotifications,
  dismissNotificationsById
} from "../actions/notifications";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAllNotifications: false,
  isLoadingModuleNotifications: false,
  isLoadingDismissNotificationsById: false,
  localNotifications:[],
  customNav:null
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeLocalNotifications(state, action) {
      state.localNotifications = action.payload;
    },
    setCustomNav(state, action) {
      state.customNav = action.payload;
    },
  },
  extraReducers: {
    [getAllNotifications.pending]: (state, action) => {
      state.isLoadingAllNotifications = true;
      state.error = false;
    },
    [getAllNotifications.fulfilled]: (state, action) => {
      const oldIds = state.allNotifications?.filter(item => item?.visibility)?.map(item => item?.id)
      const newIds = action?.payload?.notifications?.filter(item => item?.visibility)?.map(item => item?.id)
      const commonIds = _.intersection(oldIds, newIds)
      state.isLoadingAllNotifications = false;
      if ((commonIds?.length !== oldIds?.length) || (commonIds?.length !== newIds?.length)){
        state.allNotifications = action?.payload?.notifications?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      state.error = false;
    },
    [getAllNotifications.rejected]: (state, action) => {
      state.isLoadingAllNotifications = false;
      state.error = true;
    },
    [getModuleNotifications.pending]: (state, action) => {
      state.isLoadingModuleNotifications = true;
      state.error = false;
    },
    [getModuleNotifications.fulfilled]: (state, action) => {
      state.isLoadingModuleNotifications = false;
      state.moduleNotifications = action?.payload;
      state.error = false;
    },
    [getModuleNotifications.rejected]: (state, action) => {
      state.isLoadingModuleNotifications = false;
      state.error = true;
    },
    [dismissNotificationsById.pending]: (state, action) => {
      state.isLoadingDismissNotificationsById = true;
      state.error = false;
    },
    [dismissNotificationsById.fulfilled]: (state, action) => {
      state.isLoadingDismissNotificationsById = false;
      state.error = false;
    },
    [dismissNotificationsById.rejected]: (state, action) => {
      state.isLoadingDismissNotificationsById = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.notifications.error;
export const getIsLoadingAllNotifications = (state) =>
  state.notifications.isLoadingAllNotifications;
export const getIsLoadingModuleNotifications = (state) =>
  state.notifications.isLoadingModuleNotifications;
export const getIsLoadingDismissNotificationsById = (state) =>
  state.notifications.isLoadingDismissNotificationsById;
export const getAllUserNotifications = (state) => state.notifications.allNotifications
export const getModuleUserNotifications = (state) =>
  state.notifications.moduleNotifications;
export const getLocalNotifications = (state) =>
  state.notifications.localNotifications;
export const getCustomNav = (state) =>
  state.notifications.customNav;

export const {
  storeLocalNotifications,
  setCustomNav
} = notificationsSlice.actions;

export default notificationsSlice;
