import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import querystring from "query-string";
import { logoutUser } from "../actions/account";

export const getDonationById = createAsyncThunk(
    "donation/getDonationById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/donations/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getDonationsByModuleId = createAsyncThunk(
    "donation/getDonationsByModuleId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                (payload?.isUser ? `/api/donations/module/${payload?.moduleId}/module_type/${payload?.moduleName}/institution/${payload?.institutionId}/me` : `/api/donations/module/${payload?.moduleId}/module_type/${payload?.moduleName}/institution/${payload?.institutionId}`);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getAllDonationsForInstitution = createAsyncThunk(
    "donation/getAllDonationsForInstitution",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                (payload?.isUser ? `/api/donations/institution/${payload?.institutionId}/me` : `/api/donations/institution/${payload?.institutionId}`);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const createDonation = createAsyncThunk(
    "donation/createDonation",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/donations`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editDonationById = createAsyncThunk(
    "donation/editDonationById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/donations/${payload?.id}/institution/${payload?.institutionId}`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const publishDonation = createAsyncThunk(
    "donation/publishDonation",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/donations/${payload?.id}/publish`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const payDonationById = createAsyncThunk(
    "donation/payDonationById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/donations/subscriptionDetail/${payload?.id}/pay`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const cancelDonationById = createAsyncThunk(
    "donation/cancelDonationById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/donations/${payload?.id}`;
            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
