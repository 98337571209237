import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
//reducers
import { getAllUserNotifications, getCustomNav, setCustomNav } from '../../../redux/slices/notifications'
import { getIsLoadingLoginSelectInstitution, getJwt, getProfileType, setInstitutionId, getError as getLoginError, getInstitutionId } from '../../../redux/slices/account'
import { getUserInstitutionDetails, setInstitutionDetails } from '../../../redux/slices/institution'
//actions
import { dismissNotificationsById } from '../../../redux/actions/notifications'
import { handleNavigate, usePrevious } from 'src/helpers/utils';
import { getIsLoadingUserNavigation, getError } from 'src/redux/slices/navigation';
import { getUserNavigation } from 'src/redux/actions/navigation';
import { loginUserSelectInstitution } from 'src/redux/actions/account';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { enqueueSnackbar } = useSnackbar();

  const userInstitutionDetails = useSelector(getUserInstitutionDetails);
  const allUserNotifications = useSelector(getAllUserNotifications);
  const allUserNotificationsPrev = usePrevious(allUserNotifications);
  const isLoadingLoginSelectInstitution = useSelector(getIsLoadingLoginSelectInstitution);
  const isLoadingLoginSelectInstitutionPrev = usePrevious(isLoadingLoginSelectInstitution);
  const loginError = useSelector(getLoginError);
  const jwt = useSelector(getJwt);
  const institutionId = useSelector(getInstitutionId);
  const profileType = useSelector(getProfileType);
  const customNav = useSelector(getCustomNav);
  

  //naviagtions
  const error = useSelector(getError)
  const isLoadingUserNavigation = useSelector(getIsLoadingUserNavigation)
  const isLoadingUserNavigationPrev = usePrevious(isLoadingUserNavigation)
  const [open, setOpen] = useState(null);
  const [notifications, setNotifications] = useState(allUserNotifications);

  useEffect(() => {
    setNotifications(allUserNotifications);
    if (allUserNotifications && allUserNotifications?.length && allUserNotificationsPrev?.length < allUserNotifications?.length) {
      enqueueSnackbar('New Notification! Click bell to view');
    }
  }, [allUserNotifications])


  useEffect(() => {
    if (
      !loginError &&
      !isLoadingLoginSelectInstitution &&
      isLoadingLoginSelectInstitution !== isLoadingLoginSelectInstitutionPrev &&
      isLoadingLoginSelectInstitutionPrev !== undefined
    ) {
      dispatch(getUserNavigation({ jwt, institutionId: institutionId }));
    }
  }, [isLoadingLoginSelectInstitution, isLoadingLoginSelectInstitutionPrev]);

  useEffect(() => {
    if (
      !error &&
      !isLoadingUserNavigation &&
      isLoadingUserNavigation !== isLoadingUserNavigationPrev &&
      isLoadingUserNavigationPrev !== undefined
    ) {
      if (customNav) {
        handleNavigate(customNav, navigate)
      }
    }
  }, [isLoadingUserNavigation, isLoadingUserNavigationPrev]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    dispatch(dismissNotificationsById({ jwt, ids: allUserNotifications?.map(val => val?.id) }))
    setNotifications(notifications.map((noti) => Object.assign({}, noti, {
      visibility: false
    })))
  };

  const onClickNotification = (selectedNotification) => {
    dispatch(dismissNotificationsById({ jwt, ids: [selectedNotification?.id] }))
    setNotifications(
      notifications.map((noti) => {
        if (noti?.id === selectedNotification?.id) {
          return Object.assign({}, noti, {
            visibility: false
          })
        }
        else {
          return Object.assign({}, noti)
        }
      })
    );
    handleClose()
    if (pathname?.includes("institutions/all")) {
      dispatch(setCustomNav(selectedNotification))
      const filteredInstitution = userInstitutionDetails?.filter(inst => inst?._id === selectedNotification?.institutionId)?.[0]
      onClickGoToInsitution(filteredInstitution)
    }
    else {
      handleNavigate(selectedNotification, navigate, profileType)
    }
  };

  const onClickGoToInsitution = (institution) => {
    dispatch(loginUserSelectInstitution({ jwt, requestBody: { institutionId: institution?._id } }))
  }

  const unReadNotification = notifications?.filter(noti => noti?.visibility)
  const readNotification = notifications?.filter(noti => !noti?.visibility)

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={unReadNotification?.length} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {unReadNotification?.length ? unReadNotification?.length : 0} unread messages
            </Typography>
          </Box>

          {unReadNotification?.length > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ maxHeight: { xs: 340, sm: 500 } }}>
          <List
            disablePadding
            subheader={
              unReadNotification?.length > 0 && <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {unReadNotification?.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClickNotification={onClickNotification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              readNotification?.length > 0 && <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Seen
              </ListSubheader>
            }
          >
            {readNotification?.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClickNotification={onClickNotification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed', mb: 2 }} />
        {/* 
        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onClickNotification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.visibility && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => onClickNotification(notification)}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(moment(notification.created_at))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <div style={{ overflow: "hidden", textOverflow: "ellipsis", height: 70 }}>
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }} dangerouslySetInnerHTML={{ __html: notification.message }}>
        </Typography>
      </div>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
