import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkStatus,
  composeAuth,
  AuthError,
  getById,
} from "../../helpers/utils";
import querystring from "query-string";
import { logoutUser } from "../actions/account";

export const getProfileByIds = createAsyncThunk(
  "profile/getProfileByIds",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/profiles?${querystring.stringify({ ids: payload?.profileIds })}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProfileById = createAsyncThunk(
  "profile/getProfileById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/profile/me` : `/api/profiles/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addProfile = createAsyncThunk(
  "profile/addProfile",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/profiles`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProfileSchema = createAsyncThunk(
  "profile/getProfileSchema",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/profiles/schema/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProfileByInstitution = createAsyncThunk(
  "profile/getProfileByInstitution",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/profiles/institution/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      validateData.data.byId = getById(validateData?.data?.profiles, "_id");
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProfileByInstitutionTemp = createAsyncThunk(
  "profile/getProfileByInstitutionTemp",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/profile/me` : `/api/profiles/list/institution/${payload?.id}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      if (!payload?.isUser) {
        validateData.data.byId = getById(validateData?.data?.profiles?.filter(pro => pro?._id), "_id");
      }
      return validateData?.data;
    } catch (err) {
      console.log(err,"err");
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getProfileByModuleId = createAsyncThunk(
  "profile/getProfileByModuleId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/profiles/module/${payload?.module}/id/${payload?.moduleId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delProfileById = createAsyncThunk(
  "profile/delProfileById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER + `/api/profiles/${payload.profileId}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editProfileById = createAsyncThunk(
  "profile/editProfileById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/profiles/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.body),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const getProfilePermissions = createAsyncThunk(
  "profile/getProfilePermissions",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/profilePermissionObject/institution/${payload?.id}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        }
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser())
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);