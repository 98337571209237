import { createSlice } from "@reduxjs/toolkit";
import { addAdminUser, deleteUserById } from "../actions/users";
import { logoutUser } from "../actions/account";

const initialState = {
    isLoadingAddUser: false,
    error: false,
    isLoadingDeleteUser: false,
};

const userSlice = createSlice({
    name: "users",
    initialState,
    extraReducers: {
        [addAdminUser.pending]: (state, action) => {
            state.isLoadingAddUser = true;
            state.error = false;
        },
        [addAdminUser.fulfilled]: (state, action) => {
            state.isLoadingAddUser = false;
            state.error = false;
        },
        [addAdminUser.rejected]: (state, action) => {
            state.isLoadingAddUser = false;
            state.error = true;
        },//deleteUserById
        [deleteUserById.pending]: (state, action) => {
            state.isLoadingDeleteUser = true;
            state.error = false;
        },
        [deleteUserById.fulfilled]: (state, action) => {
            state.isLoadingDeleteUser = false;
            state.error = false;
        },
        [deleteUserById.rejected]: (state, action) => {
            state.isLoadingDeleteUser = false;
            state.error = true;
        },

        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getIsLoadingAddUser = (state) => state.users.isLoadingAddUser;
export const getIsLoadingDeleteUser = (state) => state.users.isLoadingDeleteUser;
export const getError = (state) => state.users.error;

export default userSlice;
