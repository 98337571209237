import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkStatus,
  composeAuth,
  AuthError,
  getById,
} from "../../helpers/utils";
import { logoutUser } from "./account";

export const getClientSchema = createAsyncThunk(
  "client/getClientSchema",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/clients/schema/${payload?.institutionId}/me` : `/api/clients/schema/${payload?.institutionId}`);
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getAllClients = createAsyncThunk(
  "client/getAllClients",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser ? `/api/clients/me/institutionId/${payload?.institutionId}` : `/api/clients/all/${payload?.institutionId}`);

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      validateData.data.byId = getById(validateData?.data?.clients, "_id");
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getClientById = createAsyncThunk(
  "client/getClientById",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/clients/${payload?.id}/me`
          : `/api/clients/${payload?.id}`);
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getClientByIdLazy = createAsyncThunk(
  "client/getClientByIdLazy",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        (payload?.isUser
          ? `/api/clients/${payload?.id}/me`
          : `/api/clients/${payload?.id}/light`);
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addClient = createAsyncThunk(
  "client/addClient",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/clients/institution`;
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editClientById = createAsyncThunk(
  "client/editClientById",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/clients/${payload?.id}`;
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delClientById = createAsyncThunk(
  "client/delClientById",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/clients/${payload?.id}`;
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editBulkClients = createAsyncThunk(
  "clients/editBulkClients",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/clients/bulk`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editBulkClientsCSV = createAsyncThunk(
  "clients/editBulkClientsCSV",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/clients/bulk-csv`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const revertClients = createAsyncThunk(
  "client/revertClients",
  async (payload, thunkApi) => {
    try {
      const url =
        `${process.env.REACT_APP_SERVER}/api/clients/revert/institution/${payload?.institutionId}`;

      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });
      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const revertClientById = createAsyncThunk(
  "client/revertClientById",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/client/revert/id/${payload?.id}`;
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);