import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
//reducers
import { getFirstName, getJwt, getEmail, getAllUserInstitutionIds, getProfile } from '../../../redux/slices/account'
import { getInstitutionDetails } from 'src/redux/slices/institution';
//actions
import { logoutUser } from '../../../redux/actions/account'
import { getUserNavigationArray } from 'src/redux/slices/navigation';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation()

  const firstName = useSelector(getFirstName);
  const email = useSelector(getEmail);
  const profile = useSelector(getProfile);
  const jwt = useSelector(getJwt);
  const userNavigation = useSelector(getUserNavigationArray);
  const institutionDetails = useSelector(getInstitutionDetails);

  const MENU_OPTIONS = [
    {
      label: 'Home',
      linkTo: "/" + userNavigation?.slice()?.sort((a, b) => {
        const sectionOrder = a?.section_order - b?.section_order
        if (sectionOrder === 0) {
          return a?.order - b?.order
        }
        else {
          return a?.section_order - b?.section_order
        }
      })?.[0]?.link,
    },
    {
      label: 'Profile',
      linkTo: '/me/profile/view',
    },
    {
      label: 'Switch Portal',
      linkTo: '/institutions/all',
    },

  ];

  if (institutionDetails?.is_bookings_module) {
    MENU_OPTIONS.push({
      label: 'My Bookings',
      linkTo: '/me/bookings/view',
    })
  }
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    dispatch(logoutUser(jwt))
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.first_name ? (profile?.first_name + (profile?.last_name ? (" " + profile?.last_name) : "")) : firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile?.email ? profile?.email : email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {(!pathname?.includes("institutions/all") && !pathname?.includes("changePassword") ? MENU_OPTIONS : [])?.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
