import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from "./account";

export const getAllHolidays = createAsyncThunk(
  "holidays/getAllHolidays",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/holidays/institution/${payload?.institutionId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const getAllHolidayList = createAsyncThunk(
  "holidays/getAllHolidayList",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        `/api/holidays/keys/institution/${payload?.institutionId}`;
      const Authorization = composeAuth(payload.jwt);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const addHolidayList = createAsyncThunk(
  "holidays/addHolidayList",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + "/api/holidays/csv";
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delHolidayList = createAsyncThunk(
  "holidays/delHolidayList",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER + `/api/holidays/key/${payload?.key}`;
      const Authorization = composeAuth(payload.jwt);
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
export const addHolidayDate = createAsyncThunk(
  "holidays/addHolidayDate",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + "/api/holidays";
      const Authorization = composeAuth(payload?.jwt);

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.data),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const delHolidayDate = createAsyncThunk(
  "holidays/delHolidayDate",
  async (payload, thunkApi) => {
    try {
      const url = process.env.REACT_APP_SERVER + `/api/holidays/${payload?.id}`;
      const Authorization = composeAuth(payload.jwt);
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getHolidayDateListByKey = createAsyncThunk(
  "holidays/getHolidayDateListByKey",
  async (payload, thunkApi) => {
    try {
      const url =
        process.env.REACT_APP_SERVER +
        `/api/holidays/key/${payload?.key}/institution/${payload?.institutionId}`;
      const Authorization = composeAuth(payload.jwt);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);