import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../redux/store';
import { useIntercom } from 'react-use-intercom';
//reducers
import { getIsLoggedIn, getJwt, getInstitutionId, getAllUserInstitutionIds, getIsLoadingVerifyEmailOtp, getError } from '../redux/slices/account'
import { getUrlOnLogin, storeUrlOnLogin } from '../redux/slices/navigation'
//actions
import { getUserNavigation } from '../redux/actions/navigation'
import { usePrevious } from 'src/helpers/utils';
import { verifyEmailOtp } from 'src/redux/actions/account';


// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pathname } = location

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoggedInPrev = usePrevious(isLoggedIn);
  const jwt = useSelector(getJwt);
  const institutionId = useSelector(getInstitutionId);
  const allUserInstitutionIds = useSelector(getAllUserInstitutionIds);
  const urlOnLogin = useSelector(getUrlOnLogin);
  const error = useSelector(getError);
  const isLoadingVerifyEmailOtp = useSelector(getIsLoadingVerifyEmailOtp);
  const isLoadingVerifyEmailOtpPrev = usePrevious(isLoadingVerifyEmailOtp);

  const {
    shutdown
  } = useIntercom();

  useEffect(() => {
    if (isLoggedIn) {
      if (!institutionId && allUserInstitutionIds && allUserInstitutionIds?.length > 1) {
        <Navigate to={"/institutions/all"} />
      }
      else {
        dispatch(getUserNavigation({ jwt, institutionId: institutionId }))
        if (urlOnLogin && pathname !== urlOnLogin && pathname !== "/") {
          const tempUrlOrgin = urlOnLogin
          dispatch(storeUrlOnLogin(null))
          return <Navigate to={tempUrlOrgin} />;
        }
        else{
          dispatch(storeUrlOnLogin(null))
          return <Navigate to={"/"} />;
        }
      }
    }
    if (isLoggedInPrev && !isLoggedIn) {
      dispatch(storeUrlOnLogin(null))
    }
  }, [isLoggedIn, allUserInstitutionIds])

  useEffect(() => {
    if (
      !isLoadingVerifyEmailOtp &&
      isLoadingVerifyEmailOtp !== isLoadingVerifyEmailOtpPrev &&
      isLoadingVerifyEmailOtpPrev !== undefined &&
      !error
    ) {
      if (isLoggedIn) {
        if (!institutionId && allUserInstitutionIds && allUserInstitutionIds?.length > 1) {
          <Navigate to={"/institutions/all"} />
        }
        else {
          dispatch(getUserNavigation({ jwt, institutionId: institutionId }))
          if (urlOnLogin && pathname !== urlOnLogin) {
            return <Navigate to={urlOnLogin} />;
          }
        }
      }
    } else if
      (!isLoadingVerifyEmailOtp &&
      isLoadingVerifyEmailOtp !== isLoadingVerifyEmailOtpPrev &&
      isLoadingVerifyEmailOtpPrev !== undefined &&
      error) {
      if (!isLoggedIn) {
        return <Navigate to={"/auth/resend-link"} />
      }
    }
  }, [isLoadingVerifyEmailOtp, isLoadingVerifyEmailOtpPrev]);

  if (!isLoggedIn) {
    const queryParams = new URLSearchParams(location.search)
    const otp = queryParams.get("otp")
    if (pathname !== "/auth" && pathname !== "/") {
      dispatch(storeUrlOnLogin(pathname))
      if (otp) {
        dispatch(verifyEmailOtp(otp));
      }
    }
    shutdown()
    return <Navigate to={"/auth"} />
  }

  return <>{children}</>;
}
