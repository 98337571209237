import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from "./account";
import querystring from "query-string";

export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/notifications`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getModuleNotifications = createAsyncThunk(
  "notifications/getModuleNotifications",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url =
        process.env.REACT_APP_SERVER +
        `/api/notifications/${payload?.moduleId}`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const dismissNotificationsById = createAsyncThunk(
  "profile/dismissNotificationsById",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/notifications/payload`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization,
        },
        body: JSON.stringify({ ids: payload?.ids }),
      });

      const response = await res.json();
      const validateData = checkStatus(response);
      return validateData?.data;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);