import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import querystring from "query-string";
import { logoutUser } from "../actions/account";

export const getCampaignsForInstitution = createAsyncThunk(
    "campaign/getCampaignsForInstitution",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/campaigns/institution/${payload?.institutionId}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getGoalsByCampaignId = createAsyncThunk(
    "campaign/getGoalsByCampaignId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/goals/campaign/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const addCampaign = createAsyncThunk(
    "campaign/addCampaign",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaigns`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const editCampaign = createAsyncThunk(
    "campaign/editCampaign",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaigns/${payload?.id}/institution/${payload?.institutionId}`;
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delCampaign = createAsyncThunk(
    "campaign/delCampaign",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/campaigns/${payload?.id}`;
            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getCampaignById = createAsyncThunk(
    "campaign/getCampaignById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/campaigns/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


