import { createSlice } from "@reduxjs/toolkit";
import { getReportTemplates } from "../actions/reportTemplate";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingGetReportTemp: false,
  reportTemplateList: [],
};

const reportTemplatesSlice = createSlice({
  name: "reportTemplates",
  initialState,
  extraReducers: {
    [getReportTemplates.pending]: (state, action) => {
      state.isLoadingGetReportTemp = true;
      state.error = false;
    },
    [getReportTemplates.fulfilled]: (state, action) => {
      state.isLoadingGetReportTemp = false;
      state.reportTemplateList = action?.payload?.data?.report_templates;
      state.error = false;
    },
    [getReportTemplates.rejected]: (state, action) => {
      state.isLoadingGetReportTemp = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.reportTemplates.error;
export const getIsLoadingReportTemp = (state) =>
  state.reportTemplates.isLoadingGetReportTemp;
export const getReportTemplateList = (state) =>
  state.reportTemplates.reportTemplateList;
export default reportTemplatesSlice;
