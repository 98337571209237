import { createSlice } from "@reduxjs/toolkit";
import { getUserNavigation } from "../actions/navigation";
import { logoutUser, loginUser, verifyEmailOtp } from "../actions/account";

const initialState = {
  error: false,
  isLoadingUserNavigation: false,
  userNavigation: [],
  urlOnLogin:null
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggleDropdownStatus(state) {
      state.changeDropdownStatus = !state.changeDropdownStatus;
    },
    storeUrlOnLogin(state, action) {
      state.urlOnLogin = action?.payload;
    },
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [getUserNavigation.pending]: (state, action) => {
      state.isLoadingUserNavigation = true;
      state.error = false;
    },
    [getUserNavigation.fulfilled]: (state, action) => {
      state.isLoadingUserNavigation = false;
      state.userNavigation = action?.payload;
      state.error = false;
    },
    [getUserNavigation.rejected]: (state, action) => {
      state.isLoadingUserNavigation = false;
      state.error = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.userNavigation = action?.payload?.navigations;
    },
    [verifyEmailOtp.fulfilled]: (state, action) => {
      state.userNavigation = action?.payload?.navigations;
    },
    [verifyEmailOtp.rejected]: (state) => {
      state.userNavigation = initialState.userNavigation;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState
    },
    [logoutUser.rejected]: (state) => {
      return initialState
    },
  },
});

export const getError = (state) => state.navigation.error;
export const getIsLoadingUserNavigation = (state) => state.navigation.isLoadingUserNavigation;
export const getUserNavigationArray = (state) => state.navigation.userNavigation;
export const getUrlOnLogin = (state) => state.navigation.urlOnLogin;

export const {
  storeUrlOnLogin
} = navigationSlice.actions;


export default navigationSlice;
