import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useSelector } from "../redux/store";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import NoNavDashboardLayout from "../layouts/dashboard/indexNoNav";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import PublicLayout from "../layouts/publicLayout";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

//slice
import { getUrlOnLogin, getUserNavigationArray } from "../redux/slices/navigation";
import { getAllUserInstitutionIds, getInstitutionId } from "../redux/slices/account";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={false} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const userNavigations = useSelector(getUserNavigationArray);
  const allUserInstitutionIds = useSelector(getAllUserInstitutionIds);
  const institutionId = useSelector(getInstitutionId);
  const urlOnLogin = useSelector(getUrlOnLogin);

  return useRoutes([
    // App Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                userNavigations
                  ? "/" +
                  ((allUserInstitutionIds && (allUserInstitutionIds?.length > 1) && !institutionId) ? "institutions/all" : urlOnLogin ? urlOnLogin : (userNavigations?.slice()?.sort((a, b) => {
                    const sectionOrder = a?.section_order - b?.section_order;
                    if (sectionOrder === 0) {
                      return a?.order - b?.order;
                    } else {
                      return a?.section_order - b?.section_order;
                    }
                  })?.[1]?.link))
                  : PATH_AFTER_LOGIN
              }
              replace
            />
          ),
          index: true,
        },
        { path: "dashboard", element: <Dashboard /> },
        { path: "dashboard/sales", element: <DashboardAnalytics1 /> },
        { path: "dashboard/marketing", element: <DashboardAnalytics2 /> },
        { path: "dashboard/unitGrid", element: <UnitGridDisplay /> },
        { path: "campaigns", element: <CampaignListing /> },
        { path: "campaign/view/:campaignId", element: <CampaignView /> },
        { path: "emailBurst/campaign/:campaignId", element: <EmailBurstAddEdit /> },
        { path: "emailBurst/campaign/:campaignId/edit/:emailBurstId", element: <EmailBurstAddEdit /> },
        { path: "emailBursts/campaign/:campaignId", element: <EmailBurstListing /> },
        { path: "emailBurst/:emailBurstId/view", element: <EmailBurstView /> },
        { path: "referrals", element: <ReferralDashboard /> },
        { path: "referral/add", element: <ReferralForm /> },
        { path: "referral/add/new", element: <FormCreator /> },
        { path: "referral/view/:id", element: <ReferralView /> },
        { path: "referrers", element: <ReferrerDashboard /> },
        { path: "referrer/view/:id", element: <ReferrerView /> },
        { path: "profiles", element: <ProfilesUtitlity /> },
        { path: "profiles/listing", element: <Profiles /> },
        { path: "profile/add/", element: <AddProfile /> },
        { path: "profile/edit/:id", element: <AddProfile /> },
        { path: "profile/view/:id", element: <ProfilesView /> },
        { path: "locations", element: <Locations /> },
        { path: "location/add", element: <AddLocations /> },
        { path: "location/edit/:id", element: <AddLocations /> },
        { path: "location/view/:id", element: <LocationView /> },
        { path: "events", element: <Events /> },
        { path: "events/schedule", element: <ScheduleEvents /> },
        { path: "events/goals/:tagId", element: <Events /> },
        { path: "products", element: <ProductPage /> },
        { path: "products/view/:id", element: <ProductView /> },
        { path: "product/add", element: <ProductForm /> },
        { path: "product/add/form/:formKey", element: <ProductForm /> },
        { path: "product/edit/:id", element: <ProductForm /> },
        { path: "product/edit/:id/form/:formKey", element: <ProductForm /> },
        { path: "clients", element: <Clients /> },
        { path: "clients/add", element: <ClientForm /> },
        { path: "clients/add/form/:formKey", element: <ClientForm /> },
        { path: "clients/edit/:id", element: <ClientForm /> },
        { path: "clients/edit/:id/form/:formKey", element: <ClientForm /> },
        { path: "clients/edit/subSection/:id", element: <ClientForm /> },
        { path: "clients/view/:id", element: <ClientView /> },
        { path: "vendors", element: <Vendors /> },
        { path: "vendors/:id/items", element: <VendorAllItems /> },
        { path: "vendors/view/:id", element: <VendorView /> },
        { path: "vendors/add", element: <VendorForm /> },
        { path: "vendors/add/form/:formKey", element: <VendorForm /> },
        { path: "vendors/edit/:id", element: <VendorForm /> },
        { path: "vendors/edit/:id/form/:formKey", element: <VendorForm /> },
        { path: "vendors/edit/subSection/:id", element: <VendorForm /> },
        { path: "resources", element: <Resources /> },
        { path: "resource/view", element: <ResourceView /> },
        { path: "goals/:id", element: <Goals /> },
        { path: "goals/tag/:tagId/view/:id", element: <ViewGoal /> },
        { path: "goals/tag/:tagId/view/:id/duplicate/:formKey", element: <DuplicateGoal /> },
        { path: "goals/edit/:goalId", element: <AddGoals /> },
        { path: "goals/add/:tagId", element: <AddGoals /> },
        { path: "goals/edit/:goalId/form/:formKey", element: <AddGoals /> },
        { path: "goals/add/:tagId/form/:formKey", element: <AddGoals /> },
        {
          path: "goals/add/:tagId/parent/:parentId",
          element: <AddGoals />,
        },
        {
          path: "goals/add/:tagId/parent/:parentId/form/:formKey",
          element: <AddGoals />,
        },
        { path: "goals/add/:tagId/:module/:moduleId", element: <AddGoals /> },
        { path: "goals/bulk/edit/:tagId", element: <BulkEditGoals /> },
        {
          path: "clients/bulk/edit", element: <BulkEditClients/>
        },
        {
          path: "clients/bulk/previous/:institutionId", element: <BulkRevertClients/>
        },
        {
          path: "locations/bulk/previous/:institutionId", element: <BulkRevertLocations/>
        },
        {
          path: "salesOrder/goal/:moduleId/type/:schemaId",
          element: <AddSalesOrder />,
        },
        {
          path: "salesOrder/goal/:moduleId/type/:schemaId/edit/:itemId",
          element: <AddSalesOrder />,
        },
        {
          path: "workOrder/goal/:moduleId/type/:schemaId",
          element: <AddWorkOrder />,
        },
        {
          path: "workOrder/add",
          element: <AddWorkOrder />,
        },
        {
          path: "workOrder/add/vendor/:vendorId",
          element: <AddWorkOrder />,
        },
        {
          path: "salesOrder/goal/:moduleId",
          element: <AddSalesOrder />,
        },
        {
          path: "contract/goal/:moduleId",
          element: <AddGoalContract />,
        },
        {
          path: "contract/vendor/:moduleId",
          element: <AddVendorContract />,
        },
        {
          path: "contract/vendor/:moduleId/template/:templateId",
          element: <AddVendorContract />,
        },
        {
          path: "contract/client/:moduleId",
          element: <AddClientContract />,
        },
        {
          path: "contract/client/:moduleId/template/:templateId",
          element: <AddClientContract />,
        },
        {
          path: "contract/goal/:moduleId/template/:templateId",
          element: <AddGoalContract />,
        },
        {
          path: "contract/product/:moduleId",
          element: <AddProductContract />,
        },
        {
          path: "contract/product/:moduleId/template/:templateId",
          element: <AddProductContract />,
        },
        {
          path: "contract/:contractId",
          element: <ViewContract />,
        },
        {
          path: "contracts",
          element: <Contracts />,
        },
        {
          path: "report/goal/:moduleId",
          element: <AddGoalReport />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "report/:reportId",
          element: <ViewReport />,
        },
        {
          path: "reports/module/:routeModuleName/:routeModuleId",
          element: <Reports />,
        },
        {
          path: "emails",
          element: <Emails />,
        },
        {
          path: "emails/:emailId",
          element: <ViewEmail />,
        },
        {
          path: "tag/:tagId/items",
          element: <IncomeItems />,
        },
        {
          path: "goal/:goalId/tag/:tagId/invoice/add",
          element: <AddInvoicing />,
        },
        {
          path: "goal/:goalId/tag/:tagId/invoice/:invoiceId",
          element: <InvoiceEdit />,
        },
        {
          path: "invoices",
          element: <Invoices />,
        },
        {
          path: "workorders",
          element: <VendorWorkOrders />,
        },
        { path: "workorders/view/:id", element: <VendorWorkorderView /> },
        { path: "workOrder/:workOrderId/invoice/:invoiceId", element: <VendorInvoiceEdit /> },
        { path: "workorders/edit/:id", element: <VendorWorkorderEdit /> },
        { path: "goal/:goalId/itemType/:schemaId", element: <GoalItemEdit /> },
        { path: "goal/:goalId/itemType/:schemaId/createWorkorder", element: <WorkorderCreationFromItem /> },
        { path: "goal/:goalId/itemType/:schemaId/createWorkorder/calendar", element: <WorkorderCreationFromItem /> },
        { path: "emailTemplate/campaign/:campaignId", element: <EmailTemplateListing /> },
        { path: "emailTemplate/add/campaign/:campaignId", element: <EmailEditor /> },
        { path: "emailTemplate/campaign/:campaignId/edit/:templateId", element: <EmailEditor /> },
        { path: "bookings/slotCreation/:moduleName/:moduleId", element: <BookingsSlotsCreation /> },
        { path: "bookings/slotCreation/:moduleName/:moduleId/edit/:templateId", element: <BookingsSlotsCreation /> },
        { path: "bookings/slotBooking/:moduleName/:moduleId", element: <BookingsSlotsGeneration /> },
        { path: "bookings/templates/:moduleName/:moduleId", element: <BookingTemplatesListing /> },
        { path: "bookings/view/:moduleName/:moduleId", element: <BookingsListing /> },
        { path: "workorder/calendar/tag/:tagId", element: <WorkorderCalender /> },
        { path: "mail", element: <Mail /> },
        { path: "mail/:mailId", element: <Mail /> },
        { path: "events/listing", element: <EventListing /> },
        { path: "nycha", element: <NychaResourceAllocation /> },
        { path: "nycha/simulation/:simulationId", element: <NychaResourceAllocation /> },
        { path: "donations/all", element: <DonationsListing /> },
        { path: "donations/view/:donationId", element: <DonationsDetails /> },
        { path: "holidays", element: <HolidaysListing /> },
        { path: "holidays/:holidayKey/list", element: <HolidaysDateListing /> }
      ],
    },

    {
      path: "/me",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "referrals", element: <ReferralDashboard /> },
        { path: "referral/add", element: <ReferralForm /> },
        { path: "referral/add/new", element: <FormCreator /> },
        { path: "referral/view/:id", element: <ReferralView /> },
        { path: "referrers", element: <ReferrerDashboard /> },
        { path: "referrer/view/:id", element: <ReferrerView /> },
        { path: "profiles", element: <ProfilesUtitlity /> },
        { path: "profiles/listing", element: <Profiles /> },
        { path: "profile/add/", element: <AddProfile /> },
        { path: "profile/edit/:id", element: <AddProfile /> },
        { path: "profile/view", element: <ProfilesView /> },
        { path: "locations", element: <Locations /> },
        { path: "location/add", element: <AddLocations /> },
        { path: "location/edit/:id", element: <AddLocations /> },
        { path: "location/view/:id", element: <LocationView /> },
        { path: "events", element: <Events /> },
        { path: "events/schedule", element: <ScheduleEvents /> },
        { path: "events/goals/:tagId", element: <Events /> },
        { path: "products", element: <ProductPage /> },
        { path: "products/view/:id", element: <ProductView /> },
        { path: "product/add", element: <ProductForm /> },
        { path: "product/add/form/:formKey", element: <ProductForm /> },
        { path: "product/edit/:id", element: <ProductForm /> },
        { path: "product/edit/:id/form/:formKey", element: <ProductForm /> },
        { path: "clients", element: <Clients /> },
        { path: "clients/add", element: <ClientForm /> },
        { path: "clients/add/form/:formKey", element: <ClientForm /> },
        { path: "clients/edit/:id", element: <ClientForm /> },
        { path: "clients/edit/:id/form/:formKey", element: <ClientForm /> },
        { path: "clients/edit/subSection/:id", element: <ClientForm /> },
        { path: "clients/view/:id", element: <ClientView /> },
        { path: "vendors", element: <Vendors /> },
        { path: "vendors/:id/items", element: <VendorAllItems /> },
        { path: "vendors/view/:id", element: <VendorView /> },
        { path: "vendors/add", element: <VendorForm /> },
        { path: "vendors/add/form/:formKey", element: <VendorForm /> },
        { path: "vendors/edit/:id", element: <VendorForm /> },
        { path: "vendors/edit/:id/form/:formKey", element: <VendorForm /> },
        { path: "vendors/edit/subSection/:id", element: <VendorForm /> },
        { path: "resources", element: <Resources /> },
        { path: "resource/view", element: <ResourceView /> },
        { path: "goals/:id", element: <Goals /> },
        { path: "goals/tag/:tagId/view/:id", element: <ViewGoal /> },
        { path: "goals/edit/:goalId", element: <AddGoals /> },
        { path: "goals/add/:tagId", element: <AddGoals /> },
        { path: "goals/edit/:goalId/form/:formKey", element: <AddGoals /> },
        { path: "goals/add/:tagId/form/:formKey", element: <AddGoals /> },
        {
          path: "goals/add/:tagId/parent/:parentId",
          element: <AddGoals />,
        },
        {
          path: "goals/add/:tagId/parent/:parentId/form/:formKey",
          element: <AddGoals />,
        },
        { path: "goals/add/:tagId/:module/:moduleId", element: <AddGoals /> },
        {
          path: "contract/goal/:moduleId",
          element: <AddGoalContract />,
        },
        {
          path: "contract/product/:moduleId",
          element: <AddProductContract />,
        },
        {
          path: "contract/:contractId",
          element: <ViewContract />,
        },
        {
          path: "contracts",
          element: <Contracts />,
        },
        {
          path: "report/goal/:moduleId",
          element: <AddGoalReport />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "report/:reportId",
          element: <ViewReport />,
        },
        {
          path: "reports/module/:routeModuleName/:routeModuleId",
          element: <Reports />,
        },
        {
          path: "emails",
          element: <Emails />,
        },
        {
          path: "emails/:emailId",
          element: <ViewEmail />,
        },
        {
          path: "invoices",
          element: <Invoices />,
        },
        {
          path: "goal/:goalId/tag/:tagId/invoice/:invoiceId",
          element: <InvoiceEdit />,
        },
        {
          path: "workorders",
          element: <VendorWorkOrders />,
        },
        { path: "workorders/view/:id", element: <VendorWorkorderView /> },
        { path: "workOrder/:workOrderId/invoice/:invoiceId", element: <VendorInvoiceEdit /> },
        { path: "workorders/edit/:id", element: <VendorWorkorderEdit /> },
        { path: "bookings/view/:moduleName/:moduleId", element: <BookingsListing /> },
        { path: "bookings/view", element: <BookingsListing /> },
        { path: "events/listing", element: <EventListing /> },
        { path: "donations/all", element: <DonationsListing /> },
        { path: "donations/view/:donationId", element: <DonationsDetails /> },
      ],
    },
    {
      path: "/public",
      element: <PublicLayout />,
      children: [
        { path: "goals/add/:tagName", element: <AddGoals /> },
        { path: "goals/add/:tagName/form/:formKey", element: <AddGoals /> }
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <NoNavDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "changePassword", element: <ChangePassword /> },
        { path: "institutions/all", element: <AllInstitutionDashboard /> },
      ],
    },
    {
      path: "/auth",
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "OtpSent", element: <OTPSent /> },
        { path: "verify-code", element: <VerifyCode /> },
        { path: "new-password", element: <NewPassword /> },
        {
          path: "resend-link", element: <GuestGuard><ResendLink /></GuestGuard>
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const ResendLink = Loadable(
  lazy(() => import("../pages/auth/ResendLink"))
);
const OTPSent = Loadable(lazy(() => import("../pages/OTPSent")));
const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const ChangePassword = Loadable(
  lazy(() => import("../components/accounts/changePassword"))
);

// Institution Selection
const AllInstitutionDashboard = Loadable(
  lazy(() => import("../components/dashboard/allInstitutionDashboard"))
);

// Dashboard
const Dashboard = Loadable(
  lazy(() => import("../components/dashboard"))
);
const DashboardAnalytics1 = Loadable(
  lazy(() => import("../components/dashboard/dashboardSales"))
);
const DashboardAnalytics2 = Loadable(
  lazy(() => import("../components/dashboard/dashboardMarketing"))
);
const UnitGridDisplay = Loadable(
  lazy(() => import("../components/dashboard/unitGridDisplay"))
);

//emails
const Emails = Loadable(lazy(() => import("../components/email/index")));
const ViewEmail = Loadable(lazy(() => import("../components/email/view")));

//sales order
const AddSalesOrder = Loadable(
  lazy(() => import("../components/goals/addSalesOrder"))
);

//work order
const AddWorkOrder = Loadable(
  lazy(() => import("../components/goals/addWorkOrder"))
);

//contracts
const AddGoalContract = Loadable(
  lazy(() => import("../components/goals/addContract"))
);
const AddVendorContract = Loadable(
  lazy(() => import("../components/vendors/addContract"))
);
const AddClientContract = Loadable(
  lazy(() => import("../components/clients/addContract"))
);
const AddProductContract = Loadable(
  lazy(() => import("../components/product/addContract"))
);
const ViewContract = Loadable(
  lazy(() => import("../components/contracts/view"))
);
const Contracts = Loadable(lazy(() => import("../components/contracts/index")));

//reports
const AddGoalReport = Loadable(
  lazy(() => import("../components/goals/addReport"))
);

const Reports = Loadable(lazy(() => import("../components/reports/index")));
const ViewReport = Loadable(lazy(() => import("../components/reports/view")));
//Goals
const AddGoals = Loadable(
  lazy(() => import("../components/goals/addGoals/addGoals"))
);
const BulkEditGoals = Loadable(
  lazy(() => import("../components/goals/bulkEdit/editGoals"))
);
const BulkEditClients = Loadable (
  lazy(()=>import("../components/clients/bulkEdit/editClients"))
)
const BulkRevertClients = Loadable (
  lazy (()=>import("../components/clients/revertBulk"))
)
const BulkRevertLocations =Loadable(
  lazy (()=>import("../components/locations/revertBulk"))

)
const ViewGoal = Loadable(lazy(() => import("../components/goals/goalsView")));
const DuplicateGoal = Loadable(lazy(() => import("../components/goals/duplicateGoals/index")));
const Goals = Loadable(lazy(() => import("../components/goals/index")));

//Profiles
const ProfilesUtitlity = Loadable(lazy(() => import("../components/profiles/profileUtilityListing/index")));
const Profiles = Loadable(lazy(() => import("../components/profiles/index")));
const ProfilesView = Loadable(
  lazy(() => import("../components/profiles/profileView"))
);
const AddProfile = Loadable(
  lazy(() => import("../components/profiles/addProfiles/profileFormItem"))
);

//Locations
const Locations = Loadable(lazy(() => import("../components/locations/index")));
const AddLocations = Loadable(
  lazy(() => import("../components/locations/addLocations/locationFormItem"))
);
const LocationView = Loadable(
  lazy(() => import("../components/locations/locationView/index"))
);

//Events
const Events = Loadable(lazy(() => import("../components/events/index")));
const ScheduleEvents = Loadable(lazy(() => import("../components/events/ScheduleIndex")));
const EventListing = Loadable(lazy(() => import("../components/events/allEvents/listing")));

//Income Items
const IncomeItems = Loadable(lazy(() => import("../components/incomeItems/index")));

//form Invoicing
const AddInvoicing = Loadable(lazy(() => import("../components/invoice/index")));
const InvoiceEdit = Loadable(lazy(() => import("../components/invoice/invoiceEdit/index")));

//vendor Invoice
const VendorInvoiceEdit = Loadable(lazy(() => import("../components/invoice/vendorInvoiceEdit")));

//invoices
const Invoices = Loadable(lazy(() => import("../components/invoice/invoiceListing/index")));

//REFERRAL
const ReferralDashboard = Loadable(
  lazy(() => import("../components/enquiry/enquirer"))
);
const ReferralView = Loadable(
  lazy(() => import("../components/enquiry/enquirer/enquiryInfo"))
);
const ReferrerDashboard = Loadable(
  lazy(() => import("../components/enquiry/referrer"))
);
const ReferralForm = Loadable(
  lazy(() => import("../components/enquiry/enquirer/enquiryForm/index"))
);
const FormCreator = Loadable(
  lazy(() => import("../components/assets/layouts/formCreator"))
);
const ReferrerView = Loadable(
  lazy(() => import("../components/enquiry/referrer/referrerInfo"))
);
const Resources = Loadable(
  lazy(() => import("../components/enquiry/resources"))
);
const ResourceView = Loadable(
  lazy(() => import("../components/enquiry/resources/resourcesView"))
);

//PRODUCT
const ProductPage = Loadable(lazy(() => import("../components/product")));
const ProductView = Loadable(
  lazy(() => import("../components/product/productsView"))
);
const ProductForm = Loadable(
  lazy(() => import("../components/product/productForm"))
);

//CLIENTS
const Clients = Loadable(lazy(() => import("../components/clients")));
const ClientForm = Loadable(
  lazy(() => import("../components/clients/clientForm/index"))
);
const ClientView = Loadable(
  lazy(() => import("../components/clients/clientView"))
);

//vendors
const Vendors = Loadable(lazy(() => import("../components/vendors")))
const VendorAllItems = Loadable(lazy(() => import("../components/vendors/vendorItems")))
const VendorForm = Loadable(
  lazy(() => import("../components/vendors/vendorForm/index"))
);
const VendorView = Loadable(
  lazy(() => import("../components/vendors/vendorView"))
);

//item
const GoalItemEdit = Loadable(
  lazy(() => import("../components/goals/goalItems/index"))
);
const WorkorderCreationFromItem = Loadable(
  lazy(() => import("../components/goals/workorderCreationFromItems/index"))
);

//email editor
const EmailTemplateListing = Loadable(
  lazy(() => import("../components/campaigns/emailTemplate/listing"))
);
const EmailEditor = Loadable(
  lazy(() => import("../components/campaigns/emailTemplate/addEdit"))
);
//Campaigns
const CampaignListing = Loadable(
  lazy(() => import("../components/campaigns/campaignListing/index"))
);
const CampaignView = Loadable(
  lazy(() => import("../components/campaigns/campaignView"))
);

//email burst
const EmailBurstAddEdit = Loadable(
  lazy(() => import("../components/campaigns/emailBurst/emailBurstAddEdit"))
);
const EmailBurstListing = Loadable(
  lazy(() => import("../components/campaigns/emailBurst/emailBurstListing/index"))
);
const EmailBurstView = Loadable(
  lazy(() => import("../components/campaigns/emailBurst/emailBurstView"))
);

//Bookings
const BookingsSlotsCreation = Loadable(
  lazy(() => import("../components/bookings/index"))
);
const BookingsSlotsGeneration = Loadable(
  lazy(() => import("../components/bookings/slotBooking/index"))
);
const BookingTemplatesListing = Loadable(
  lazy(() => import("../components/bookings/bookingTemplatesListing/index"))
);
const BookingsListing = Loadable(
  lazy(() => import("../components/bookings/viewBookings/index"))
);

//WO calender
const WorkorderCalender = Loadable(
  lazy(() => import("../components/workorderCalender/index"))
);

//Nycha
const NychaResourceAllocation = Loadable(
  lazy(() => import("../components/nychaResource/index"))
);

//donations
const DonationsListing = Loadable(
  lazy(() => import("../components/donations/donationsListing"))
);
const DonationsDetails = Loadable(
  lazy(() => import("../components/donations/donationsView"))
);
//Holidays
const HolidaysListing = Loadable(
  lazy(() => import("../components/holidays/index"))
);
const HolidaysDateListing = Loadable(
  lazy(() => import("../components/holidays/holidaysDateListing"))
)
//Email Templates
//workorders
const VendorWorkOrders = Loadable(lazy(() => import("../components/vendorWorkorder/vendorWorkorderListing/index")));
const VendorWorkorderView = Loadable(lazy(() => import("../components/vendorWorkorder/vendorWorkorderView/index")));
const VendorWorkorderEdit = Loadable(lazy(() => import("../components/vendorWorkorder/vendorWorkorderForm/index")));
//misc
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));

//mail
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));